import kv1 from "../../assets/coupleFolders/keerthana+varun(prewedding)/kv1 (1).webp";
import kv2 from "../../assets/coupleFolders/keerthana+varun(prewedding)/kv2 (1).webp";
import kv3 from "../../assets/coupleFolders/keerthana+varun(prewedding)/kv3 (1).webp";
import kv4 from "../../assets/coupleFolders/keerthana+varun(prewedding)/kv4 (1).webp";
import kv5 from "../../assets/coupleFolders/keerthana+varun(prewedding)/kv5 (1).webp";
import kv6 from "../../assets/coupleFolders/keerthana+varun(prewedding)/kv6 (1).webp";
import kv7 from "../../assets/coupleFolders/keerthana+varun(prewedding)/kv7 (1).webp";
import kv8 from "../../assets/coupleFolders/keerthana+varun(prewedding)/kv8 (1).webp";
import kv9 from "../../assets/coupleFolders/keerthana+varun(prewedding)/kv9 (1).webp";
import kv10 from "../../assets/coupleFolders/keerthana+varun(prewedding)/kv10 (1).webp";
import kv11 from "../../assets/coupleFolders/keerthana+varun(prewedding)/kv11 (1).webp";
import kv12 from "../../assets/coupleFolders/keerthana+varun(prewedding)/kv12 (2).webp";
import kv13 from "../../assets/coupleFolders/keerthana+varun(prewedding)/kv13 (1).webp";
import kv14 from "../../assets/coupleFolders/keerthana+varun(prewedding)/kv14 (1).webp";

const keerthanaVarunPreweddingData = [
  kv1,
  kv2,
  kv3,
  kv4,
  kv5,
  kv6,
  kv7,
  kv8,
  kv9,
  kv10,
  kv11,
  kv12,
  kv13,
  kv14,
];

window.addEventListener("scroll", reveal);

function reveal() {
  let reveals = document.querySelectorAll(".grid-item");

  for (let i = 0; i < reveals.length; i++) {
    let windowHeight = window.innerHeight;
    let revealTop = reveals[i].getBoundingClientRect().top;
    let revealPoint = 50; // Adjust this value according to when you want the animation to trigger

    if (revealTop < windowHeight - revealPoint) {
      reveals[i].classList.add("animated", "zoom-in");
      reveals[i].classList.remove("zoom-out");
    } else {
      reveals[i].classList.add("animated", "zoom-out");
      reveals[i].classList.remove("zoom-in");
    }
  }
}

export default function KeerthanaVarun() {
  return (
    <>
      <div className="bg-[#f5f1eb] pb-[70px]">
        <div className="w-full h-[90px] bg-black"></div>
        <p className="xl:text-[40px] text-[20px] font-[ameralays] tracking-widest font-extrabold capitalize text-center mt-[50px] mb-[30px] xl:mt-[70px]">
          Keerthana & Varun
        </p>
        <p className="text-[#847f81] font-[BaskervilleRegular] text-[14px] text-center mt-[20px] xl:pl-[270px] xl:pr-[270px] xl:mb-[20px] pl-[15px] pr-[15px] mb-[20px]">
          Keerthana and Varun's pre-wedding photos radiate love and excitement.
          Each frame captures their unique bond, filled with joy, laughter, and
          tender moments. From playful smiles to intimate glances, their journey
          unfolds beautifully before the camera. These images showcase the deep
          connection and anticipation they share as they approach their big day.
          Dive into their enchanting love story and celebrate the magic of their
          relationship. Let their captivating photos inspire your own love
          story.
        </p>
        <p className="text-[#847f81] font-[BaskervilleRegular] text-[14px] text-center mt-[20px] xl:pl-[270px] xl:pr-[270px] xl:mb-[70px] pl-[15px] pr-[15px] mb-[50px] italic">
          Hampi, Karnataka, India
        </p>

        <div className="flex flex-col xl:items-center gap-[20px] pl-[15px] pr-[15px] xl:pl-0 xl:pr-0">
          {keerthanaVarunPreweddingData.map((item, index) => (
            <div
              key={index}
              className="flex flex-col grid-item animated zoom-in "
            >
              <img
                src={item}
                alt="premium wedding photography"
                loading="lazy"
                className="xl:w-[1000px] w-full h-full object-cover"
              />
            </div>
          ))}
        </div>

        <p className="xl:text-[30px] text-[20px] font-[services] font-extrabold capitalize text-center mt-[70px] xl:pl-0 xl:pr-0 pl-[15px] pr-[15px]">
          Can't wait to capture the moments with us ?
        </p>
        <div className="flex justify-center mt-[40px] pl-[15px] pr-[15px] xl:pl-0 xl:pr-0">
          <a href="/contact">
            {" "}
            <button className="bg-[#875239] p-[15px] w-[200px] text-[14px] font-semibold text-white hover:bg-[#ba9a8b] font-[BaskervilleRegular]">
              Book Us Now !
            </button>
          </a>
        </div>
      </div>
    </>
  );
}
