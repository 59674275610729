import pr1 from "../../assets/coupleFolders/pragya+rithuraj(engagement)/pr1 (1).webp";
import pr2 from "../../assets/coupleFolders/pragya+rithuraj(engagement)/pr2 (1).webp";
import pr3 from "../../assets/coupleFolders/pragya+rithuraj(engagement)/pr3 (1).webp";
import pr4 from "../../assets/coupleFolders/pragya+rithuraj(engagement)/pr4 (1).webp";
import pr5 from "../../assets/coupleFolders/pragya+rithuraj(engagement)/pr5 (1).webp";
import pr6 from "../../assets/coupleFolders/pragya+rithuraj(engagement)/pr6 (1).webp";
import pr7 from "../../assets/coupleFolders/pragya+rithuraj(engagement)/pr7 (1).webp";
import pr8 from "../../assets/coupleFolders/pragya+rithuraj(engagement)/pr8 (1).webp";
import pr9 from "../../assets/coupleFolders/pragya+rithuraj(engagement)/pr9 (1).webp";
import pr10 from "../../assets/coupleFolders/pragya+rithuraj(engagement)/pr10 (1).webp";
import pr11 from "../../assets/coupleFolders/pragya+rithuraj(engagement)/pr11 (1).webp";
import pr12 from "../../assets/coupleFolders/pragya+rithuraj(engagement)/pr12 (1).webp";
import pr13 from "../../assets/coupleFolders/pragya+rithuraj(engagement)/pr13 (1).webp";
import pr14 from "../../assets/coupleFolders/pragya+rithuraj(engagement)/pr14 (2).webp";
import pr15 from "../../assets/coupleFolders/pragya+rithuraj(engagement)/pr15 (1).webp";
import pr16 from "../../assets/coupleFolders/pragya+rithuraj(engagement)/pr16 (1).webp";
import pr17 from "../../assets/coupleFolders/pragya+rithuraj(engagement)/pr17 (2).webp";
import pr18 from "../../assets/coupleFolders/pragya+rithuraj(engagement)/pr18 (1).webp";
import pr19 from "../../assets/coupleFolders/pragya+rithuraj(engagement)/pr19 (1).webp";
import pr20 from "../../assets/coupleFolders/pragya+rithuraj(engagement)/pr20 (1).webp";
import pr21 from "../../assets/coupleFolders/pragya+rithuraj(engagement)/pr21 (1).webp";
import pr22 from "../../assets/coupleFolders/pragya+rithuraj(engagement)/pr22 (1).webp";
import pr23 from "../../assets/coupleFolders/pragya+rithuraj(engagement)/pr23 (1).webp";
import pr24 from "../../assets/coupleFolders/pragya+rithuraj(engagement)/pr24 (1).webp";
import pr25 from "../../assets/coupleFolders/pragya+rithuraj(engagement)/pr25 (1).webp";

const pragyaRithurajEngagementdata = [
  pr1,
  pr2,
  pr3,
  pr4,
  pr5,
  pr6,
  pr7,
  pr8,
  pr9,
  pr10,
  pr11,
  pr12,
  pr13,
  pr14,
  pr15,
  pr16,
  pr17,
  pr18,
  pr19,
  pr20,
  pr21,
  pr22,
  pr23,
  pr24,
  pr25,
];

window.addEventListener("scroll", reveal);

function reveal() {
  let reveals = document.querySelectorAll(".grid-item");

  for (let i = 0; i < reveals.length; i++) {
    let windowHeight = window.innerHeight;
    let revealTop = reveals[i].getBoundingClientRect().top;
    let revealPoint = 50; // Adjust this value according to when you want the animation to trigger

    if (revealTop < windowHeight - revealPoint) {
      reveals[i].classList.add("animated", "zoom-in");
      reveals[i].classList.remove("zoom-out");
    } else {
      reveals[i].classList.add("animated", "zoom-out");
      reveals[i].classList.remove("zoom-in");
    }
  }
}
export default function PragyaRithuraj() {
  return (
    <>
      <div className="bg-[#f5f1eb] pb-[70px]">
        <div className="w-full h-[90px] bg-black"></div>
        <p className="xl:text-[40px] text-[20px] font-[ameralays] tracking-widest font-extrabold capitalize text-center mt-[50px] mb-[30px] xl:mt-[70px]">
          Pragya & Rithuraj
        </p>
        <p className="text-[#847f81] font-[BaskervilleRegular] text-[14px] text-center mt-[20px] xl:pl-[270px] xl:pr-[270px] xl:mb-[20px] pl-[15px] pr-[15px] mb-[20px]">
          Love has no boundaries and limits.It's endless and boundless.The two
          beautiful souls Pragya & Rithuraj has started to nurture their ever
          lasting bond with first step as the engagement. The elegant North -
          Indian style of ring ceremony fabricates the splendid vibe for the
          frame. Each and every photograph emphasizes beauty of love.
        </p>
        <p className="text-[#847f81] font-[BaskervilleRegular] text-[14px] text-center mt-[20px] xl:pl-[270px] xl:pr-[270px] xl:mb-[70px] pl-[15px] pr-[15px] mb-[50px] italic">
          Bangalore, India
        </p>

        <div className="flex flex-col xl:items-center gap-[20px] pl-[15px] pr-[15px] xl:pl-0 xl:pr-0">
          {pragyaRithurajEngagementdata.map((item, index) => (
            <div
              key={index}
              className="flex flex-col grid-item animated zoom-in "
            >
              <img
                src={item}
                alt="premium prewedding photography"
                loading="lazy"
                className="xl:w-[1000px] w-full h-full object-cover"
              />
            </div>
          ))}
        </div>

        <p className="xl:text-[30px] text-[20px] font-[services] font-extrabold capitalize text-center mt-[70px] xl:pl-0 xl:pr-0 pl-[15px] pr-[15px]">
          Can't wait to capture the moments with us ?
        </p>
        <div className="flex justify-center mt-[40px] pl-[15px] pr-[15px] xl:pl-0 xl:pr-0">
          <a href="/contact">
            {" "}
            <button className="bg-[#875239] p-[15px] w-[200px] text-[14px] font-semibold text-white hover:bg-[#ba9a8b] font-[BaskervilleRegular]">
              Book Us Now !
            </button>
          </a>
        </div>
      </div>
    </>
  );
}
