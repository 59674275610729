import { cls } from "../components/Utility";
import DesktopWa from "../components/DesktopWa";
import wedding from "../assets/videos/home.webm";
import circlebanner from "../assets/pictures/home/R & N (1) (1).webp";
import instagram1 from "../assets/pictures/home/instagram_111 (1).webp";
import instagram2 from "../assets/pictures/home/instagram22 (3).webp";
import instagram3 from "../assets/pictures/home/instagram33 (2).webp";
import instagram4 from "../assets/pictures/home/instagram44 (2).webp";
import instagram5 from "../assets/pictures/home/pooja&amruth (1).webp";
import instagram6 from "../assets/pictures/home/instagram66 (2).webp";
import instagram7 from "../assets/pictures/home/instagram_777 (1).webp";
import instagram8 from "../assets/pictures/home/instagram_888 (1).webp";
import instagram9 from "../assets/pictures/home/instagram99 (2).webp";
import section1 from "../assets/pictures/home/section1 (1).webp";
import section2 from "../assets/pictures/home/section2 (1).webp";
import section3 from "../assets/pictures/home/section3 (2).webp";
import weddingicon from "../assets/pictures/home/wedding1icon.png";
import vedioicon from "../assets/pictures/home/vedio1icon.png";
import preweddingicon from "../assets/pictures/home/prewedding1icon.png";
import recent1 from "../assets/coupleFolders/pragya+rithuraj(engagement)/pr23 (1).webp";
import recent2 from "../assets/coupleFolders/shivani+balaji (engagement)/titlecard2 (1).webp";
import recent3 from "../assets/coupleFolders/keerthana+varun(prewedding)/kv4 (1).webp";

const offerData = [
  {
    imgsrc: weddingicon,
    title: "Innovative Wedding Photography",
    description:
      "Turn your wedding day into a storybook of unforgettable moments, capturing every emotion and detail with a fresh, artistic touch.",
  },
  {
    imgsrc: vedioicon,
    title: "4K Cinematic Wedding Films",
    description:
      "Experience your wedding day like a movie, captured in stunning 4K quality. Relive every beautiful moment with our cinematic storytelling.",
  },
  {
    imgsrc: preweddingicon,
    title: "Creative Pre-Wedding Photography",
    description:
      "Turn your love story into art with our inventive pre-wedding photos. Discover beautiful, one-of-a-kind memories before your big day.",
  },
];

const recentWorkData = [
  {
    link: "/pragya-rithuraj-engagement",
    imgsrc: recent1,
    coupleName: "Pragya & Rithuraj",
    description: `Dive into the world where treasure of love vibes between
                rithuraj & pragya which is eternal.`,
  },

  {
    link: "/shivani-balaji-engagement",
    imgsrc: recent2,
    coupleName: "Shivani & Balaji",
    description: `Step into the enchanting love story of shivani and balaji, a bond that binds forever.`,
  },
  {
    link: "/keerthana-varun-pre-wedding",
    imgsrc: recent3,
    coupleName: "Keerthana & Varun",
    description: ` Experience the ravishing bond of keerthana & varun , where love's magic never fades.`,
  },
];

export default function Home() {
  return (
    <div
      className={cls(
        "bg-[#f5f1eb] pb-[70px] relative",
        "xl:block xl:mt-[0] xl:relative"
      )}
    >
      <div className="hero1">
        <p className="xl:text-[40px] text-[30px] font-[ameralays] tracking-widest font-extrabold capitalize text-white ">
          Capturing Magical Moments
        </p>
      </div>

      <p
        className={cls(
          "text-center pl-[15px] pr-[15px] font-[ameralays] text-[20px] font-extrabold mt-[30px]",
          "xl:text-center xl:p-0 xl:text-[35px] xl:tracking-widest xl:mt-[60px]"
        )}
      >
        An Unique<span className="xl:ml-[30px] ml-[20px]">Approach</span>
      </p>

      <p
        className={cls(
          " pl-[15px] pr-[15px] font-[ameralays] text-[20px] font-bold mt-[10px] text-center",
          " xl:p-0 xl:text-[35px] xl:tracking-widest xl:mt-[20px] "
        )}
      >
        To Describe Love & Romance
      </p>

      <div
        className={cls(
          "flex flex-col gap-[0.8px] pl-[15px] pr-[15px] mt-[60px]",
          "xl:p-0 xl:items-center"
        )}
      >
        <img
          src={section1}
          alt="best wedding photography"
          loading="lazy"
          className="xl:w-[1200px] xl:h-[800px] xl:object-cover w-full h-full object-contain"
        />
        <img
          src={section2}
          alt="best conceptual photography"
          loading="lazy"
          className="xl:w-[1200px] xl:h-[600px] xl:object-cover w-full h-full object-contain"
        />

        <img
          src={section3}
          alt="best couple shoots"
          loading="lazy"
          className="xl:w-[1200px] xl:h-[800px] xl:object-cover w-full h-full object-contain"
        />
      </div>

      <div className="hidden xl:flex xl:flex-row xl:justify-center pl-[25px] pr-[25px] mt-[70px] mb-[30px] ml-[200px]">
        <img
          src={circlebanner}
          alt="best bride"
          className="w-[600px] h-[600px] object-cover rounded-full relative"
        />
        <div className="absolute w-[500px] h-[500px] left-[18%] rounded-full bg-white">
          <div className="flex flex-col p-[80px] gap-[15px]">
            <p className="font-[ameralays] font-bold text-[25px] text-center">
              Love In Every Hue
            </p>
            <p className="self-start font-[BaskervilleRegular] text-[#847f81] text-[14px] mt-[20px]">
              step into our world of wonder, where every click of the shutter is
              a brushstroke, painting a masterpiece of emotion. Let us turn your
              special day into a timeless treasure trove of memories, where it's
              a magical reality.
            </p>
            <div className="self-center">
              <a href="/portfolio">
                {" "}
                <button className="bg-[#875239] text-white text-[14px] font-semibold font-[BaskervilleRegular] w-[200px] p-[10px] text-center mt-[50px] hover:bg-[#e3cfc6]">
                  Explore More
                </button>
              </a>
            </div>
          </div>{" "}
        </div>
      </div>

      <div className="xl:hidden flex mt-[50px] mb-[160px] relative pl-[15px] pr-[15px]">
        <img
          src={circlebanner}
          alt="best bride capture"
          className="w-[330px] h-[330px] object-cover rounded-full  "
        />
        <div className="absolute w-[300px] h-[300px] rounded-full bg-white -bottom-[140px] left-[50px]">
          <div className="flex flex-col p-[40px] ">
            <p className="font-[ameralays] font-bold text-[20px] text-center mt-[10px]">
              Love In Every Hue
            </p>

            <p className="self-start font-[BaskervilleRegular] text-[#847f81] text-[10px] mt-[20px]">
              step into our world of wonder, where every click of the shutter is
              a brushstroke, painting a masterpiece of emotion. Let us turn your
              special day into a timeless treasure trove of memories, where it's
              a magical reality.
            </p>
            <div className="self-center">
              <a href="/portfolio">
                {" "}
                <button className="bg-[#875239] text-white text-[10px] font-semibold font-[BaskervilleRegular] w-[150px] p-[10px] text-center mt-[30px] hover:bg-[#e3cfc6]">
                  Explore More
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>

      <p
        className={cls(
          "pl-[15px] pr-[15px] text-center font-[BaskervilleRegular] text-[#875239] text-[14px]",
          "xl:pl-0 xl:text-[16px] "
        )}
      >
        Two beautiful souls make love to nurture their bond. We Stories by RG{" "}
        <span className="italic xl:text-[20px] font-bold ml-[5px] mr-[5px]">
          Capture
        </span>{" "}
        ravishing memories and preserve them in elegant lens.
      </p>

      <div className="w-full mt-[30px] overflow-hidden object-contain">
        <video
          autoPlay
          loop
          muted
          style={{
            width: "100%",
            height: "100%",
            clipPath:
              "polygon(0% 0%, 50% 15%, 100% 0%, 100% 100%, 50% 85%, 0 100%)",
          }}
        >
          <source src={wedding} type="video/mp4" />
        </video>
      </div>

      <p className="mt-[30px] mb-[10px] xl:block hidden text-[35px] font-semibold text-center font-[ameralays] tracking-widest">
        Have an Eye Feast With Adorable Gallery...
      </p>
      <div className="text-[#847f81] font-[BaskervilleRegular] text-[18px] text-center mt-[20px] xl:block hidden mb-[20px] ">
        Want to explore more about our adorable work check it out at our
        <a
          href="https://www.instagram.com/storiesby.rg/"
          aria-label="click here"
        >
          <span className="text-[#875239] uppercase ml-[10px]">instagram</span>
        </a>{" "}
        ...!
      </div>
      <div className="hidden xl:flex xl:flex-row xl:justify-center">
        <div className="grid grid-cols-3 gap-[5px] mt-[30px]">
          <a
            href="https://www.instagram.com/storiesby.rg/"
            aria-label="click here"
          >
            <div className="image-container11">
              <img
                src={instagram1}
                alt="candid pictures"
                loading="lazy"
                className="w-[400px] h-[500px] object-cover"
              />
            </div>
          </a>
          <a
            href="https://www.instagram.com/storiesby.rg/"
            aria-label="click here"
          >
            <div className="image-container22">
              <img
                src={instagram2}
                alt="conceptual photoshoot"
                loading="lazy"
                className="w-[400px] h-[500px] object-cover "
              />
            </div>
          </a>
          <a
            href="https://www.instagram.com/storiesby.rg/"
            aria-label="click here"
          >
            {" "}
            <div className="image-container33">
              <img
                src={instagram3}
                alt="best potraits"
                loading="lazy"
                className="w-[400px] h-[500px] object-cover "
              />
            </div>
          </a>
          <a
            href="https://www.instagram.com/storiesby.rg/"
            aria-label="click here"
          >
            <div className="image-container44">
              <img
                src={instagram4}
                alt="best bridal photography"
                loading="lazy"
                className="w-[400px] h-[500px] object-cover "
              />
            </div>
          </a>
          <a
            href="https://www.instagram.com/storiesby.rg/"
            aria-label="click here"
          >
            <div className="image-container55">
              <img
                src={instagram5}
                loading="lazy"
                alt="best groom photography"
                className="w-[400px] h-[500px] object-cover "
              />
            </div>
          </a>

          <a
            href="https://www.instagram.com/storiesby.rg/"
            aria-label="click here"
          >
            <div className="image-container66">
              <img
                src={instagram6}
                alt="gowda wedding"
                loading="lazy"
                className="w-[400px] h-[500px] object-cover "
              />
            </div>
          </a>
          <a
            href="https://www.instagram.com/storiesby.rg/"
            aria-label="click here"
          >
            <div className="image-container77">
              <img
                src={instagram7}
                alt="reddy wedding"
                loading="lazy"
                className="w-[400px] h-[500px] object-cover "
              />
            </div>
          </a>
          <a
            href="https://www.instagram.com/storiesby.rg/"
            aria-label="click here"
          >
            <div className="image-container88">
              <img
                src={instagram8}
                alt="tamil wedding"
                loading="lazy"
                className="w-[400px] h-[500px] object-cover 5"
              />
            </div>
          </a>
          <a
            href="https://www.instagram.com/storiesby.rg/"
            aria-label="click here"
          >
            <div className="image-container99">
              <img
                src={instagram9}
                alt="christian wedding"
                loading="lazy"
                className="w-[400px] h-[500px] object-cover "
              />
            </div>
          </a>
        </div>
      </div>

      <p className="mt-[30px] mb-[10px] block xl:hidden text-[20px] leading-[47px] font-extrabold text-center font-[ameralays] tracking-widest pl-[15px] pr-[15px]">
        Have an Eye Feast With adorable gallery...
      </p>
      <div className="text-[#847f81] font-[BaskervilleRegular] text-[16px] text-center mt-[20px] block xl:hidden pl-[15px] pr-[15px] ">
        Want to explore more about our adorable work check it out at our
        <a
          href="https://www.instagram.com/storiesby.rg/"
          aria-label="click here"
        >
          <span className="text-[#875239] uppercase ml-[10px]">instagram</span>
        </a>{" "}
        ...!
      </div>

      <div className="grid grid-cols-1 gap-[10px] xl:hidden mt-[30px] pl-[15px] pr-[15px]">
        <a
          href="https://www.instagram.com/storiesby.rg/"
          aria-label="click here"
        >
          <img
            src={instagram1}
            alt="goa prewedding"
            loading="lazy"
            className="w-full h-[400px] object-cover"
          />
        </a>
        <a
          href="https://www.instagram.com/storiesby.rg/"
          aria-label="click here"
        >
          {" "}
          <img
            src={instagram2}
            alt="bagalore prewedding"
            loading="lazy"
            className="w-full  h-[400px] object-cover"
          />
        </a>

        <a
          href="https://www.instagram.com/storiesby.rg/"
          aria-label="click here"
        >
          {" "}
          <img
            src={instagram3}
            alt="kerala shoot"
            loading="lazy"
            className="w-full  h-[400px] object-cover"
          />
        </a>
        <a
          href="https://www.instagram.com/storiesby.rg/"
          aria-label="click here"
        >
          {" "}
          <img
            src={instagram4}
            alt="premium photography"
            loading="lazy"
            className="w-full  h-[400px] object-cover"
          />
        </a>
        <a
          href="https://www.instagram.com/storiesby.rg/"
          aria-label="click here"
        >
          {" "}
          <img
            src={instagram5}
            alt="rajasthan prewedding"
            loading="lazy"
            className="w-full  h-[400px] object-cover"
          />
        </a>
        <a
          href="https://www.instagram.com/storiesby.rg/"
          aria-label="click here"
        >
          {" "}
          <img
            src={instagram6}
            alt="traditional shoot"
            loading="lazy"
            className="w-full  h-[400px] object-cover"
          />
        </a>
        <a
          href="https://www.instagram.com/storiesby.rg/"
          aria-label="click here"
        >
          {" "}
          <img
            src={instagram7}
            alt="best conceptual prewedding"
            loading="lazy"
            className="w-full  h-[400px] object-cover"
          />
        </a>
        <a
          href="https://www.instagram.com/storiesby.rg/"
          aria-label="click here"
        >
          {" "}
          <img
            src={instagram8}
            alt="konkani wedding"
            loading="lazy"
            className="w-full  h-[400px] object-cover"
          />
        </a>
        <a
          href="https://www.instagram.com/storiesby.rg/"
          aria-label="click here"
        >
          {" "}
          <img
            src={instagram9}
            alt="bhramin wedding"
            loading="lazy"
            className="w-full  h-[400px] object-cover"
          />
        </a>
      </div>

      <p
        className={cls(
          "text-[20px] font-[ameralays] tracking-widest text-center font-bold mt-[30px]",
          " xl:text-[35px] xl:mt-[70px]"
        )}
      >
        What We Offer{" "}
      </p>
      <div
        className={cls(
          "flex flex-col gap-[30px] mt-[40px] pl-[15px] pr-[15px]",
          "xl:flex-row xl:justify-center xl:pl-0 xl:pr-0 xl:gap-[10px]"
        )}
      >
        {offerData.map((item, index) => (
          <div
            key={index}
            className="flex flex-col gap-[10px] items-center xl:w-[400px] w-full"
          >
            <img
              src={item.imgsrc}
              alt="best services we offer in bangalore"
              className="w-[60px] h-[60px] object-contain"
            />
            <p className="text-[20px] font-bold font-[Caps] mt-[20px] text-[#875239]">
              {item.title}
            </p>
            <p className="text-[14px] text-[#847f81] font-[BaskervilleRegular]">
              {item.description}
            </p>
          </div>
        ))}
      </div>
      <div className="flex xl:justify-center justify-around ">
        <a href="/services">
          {" "}
          <p className="mt-[40px] underline1 hover:text-[#875239] focus:text-[#875239] text-[14px] text-center text-[#847f81] font-[BaskervilleRegular] font-semibold">
            VIEW ALL SERVICES ➔
          </p>
        </a>
      </div>

      <p className="mt-[70px] font-bold font-[ameralays] text-center xl:text-[35px] text-[20px] tracking-widest">
        {" "}
        Watch Our Recent Work
      </p>

      <div className="xl:flex xl:flex-col xl:items-center xl:gap-[10px] xl:mt-[40px] hidden">
        <a href="/pragya-rithuraj-engagement">
          {" "}
          <div className="image-container">
            <img src={recent1} alt="Recent Image" className="recent-image" />
            <div className="overlay">
              <p className="text-[25px] font-bold font-[ameralays] text-center">
                Pragya & Rithuraj
              </p>
              <p className="text-[14px] font-semibold mt-[20px] font-[BaskervilleRegular]">
                Dive into the world where treasure of love vibes between pragya
                & rithuraj which is eternal.
              </p>
              <div className="flex justify-center">
                <button className="w-[150px] bg-[#875239] hover:bg-[#e3cfc6]  p-[10px] text-white font-semibold text-[14px] font-[BaskervilleRegular] mt-[30px]">
                  Dive in ➔
                </button>
              </div>
            </div>
          </div>
        </a>
        <div className="flex gap-[10px]">
          <a href="/shivani-balaji-engagement">
            {" "}
            <div className="image-container1">
              <img
                src={recent2}
                alt="Recent Image1"
                className="recent-image1"
              />
              <div className="overlay1">
                <p className="text-[25px] font-bold font-[ameralays] text-center">
                  Shivani & Balaji
                </p>
                <p className="text-[14px] font-semibold mt-[20px] font-[BaskervilleRegular]">
                  Step into the enchanting love story of shivani and balaji, a
                  bond that binds forever.
                </p>
                <div className="flex justify-center">
                  <button className="w-[150px] bg-[#875239] hover:bg-[#e3cfc6] p-[10px] text-white font-semibold text-[14px] font-[BaskervilleRegular] mt-[30px]">
                    Dive in ➔
                  </button>
                </div>
              </div>
            </div>
          </a>

          <div className="image-container2">
            <img src={recent3} alt="Recent Image2" className="recent-image2" />
            <div className="overlay2">
              <p className="text-[25px] font-bold font-[ameralays] text-center">
                Keerthana & Varun
              </p>
              <p className="text-[14px] font-semibold mt-[20px] font-[BaskervilleRegular]">
                Experience the ravishing bond of keerthana & varun , where
                love's magic never fades.
              </p>
              <a href="/keerthana-varun-pre-wedding">
                {" "}
                <div className="flex justify-center">
                  <button className="w-[150px] bg-[#875239] hover:bg-[#e3cfc6] p-[10px] text-white font-semibold text-[14px] font-[BaskervilleRegular] mt-[30px]">
                    Dive in ➔
                  </button>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>

      <div
        className={cls(
          "flex flex-col gap-[40px] mt-[40px] pl-[15px] pr-[15px]",
          "xl:hidden"
        )}
      >
        {recentWorkData.map((item, index) => (
          <div key={index} className="image-containerm1">
            <img
              src={item.imgsrc}
              alt="Recent Imagem1"
              className="recent-imagem1"
            />
            <div className="overlaym1">
              <p className="text-[18px] font-bold font-[ameralays] text-center tracking-wide">
                {item.coupleName}
              </p>
              <p className="text-[14px] font-semibold mt-[20px] font-[BaskervilleRegular] pl-[20px] pr-[20px]">
                {item.description}
              </p>
              <a href={item.link}>
                <div className="flex justify-center">
                  <button className="w-[150px] bg-[#875239] hover:bg-[#e3cfc6]  p-[10px] text-white font-semibold text-[14px] font-[BaskervilleRegular] mt-[30px]">
                    Dive in ➔
                  </button>
                </div>
              </a>
            </div>
          </div>
        ))}
      </div>
      <p className="xl:text-[30px] text-[20px] font-[services] font-extrabold capitalize text-center mt-[70px] xl:pl-0 xl:pr-0 pl-[15px] pr-[15px]">
        Can't wait to capture the moments with us ?
      </p>
      <div className="flex justify-center mt-[40px] pl-[15px] pr-[15px] xl:pl-0 xl:pr-0">
        <a href="/contact">
          {" "}
          <button className="bg-[#875239] p-[15px] w-[200px] text-[14px] font-semibold text-white hover:bg-[#ba9a8b] font-[BaskervilleRegular]">
            Book Us Now !
          </button>
        </a>
      </div>

      <div className="chat-icon">
        <DesktopWa />
      </div>
    </div>
  );
}
