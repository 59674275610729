import ag1 from "../assets/coupleFolders/aishwarya + ganapathi(wedding)/aish1 (1).webp"
import ag2 from "../assets/coupleFolders/aishwarya + ganapathi(wedding)/aish2 (1).webp"
import ag3 from "../assets/coupleFolders/aishwarya + ganapathi(wedding)/aish3 (1).webp"
import ag4 from "../assets/coupleFolders/aishwarya + ganapathi(wedding)/aish4 (1).webp"
import ag5 from "../assets/coupleFolders/aishwarya + ganapathi(wedding)/aish5 (1).webp"
import ag6 from "../assets/coupleFolders/aishwarya + ganapathi(wedding)/aish6 (2).webp"
import ag7 from "../assets/coupleFolders/aishwarya + ganapathi(wedding)/aish7 (1).webp"
import ag8 from "../assets/coupleFolders/aishwarya + ganapathi(wedding)/aish8 (1).webp"
import ag9 from "../assets/coupleFolders/aishwarya + ganapathi(wedding)/aish9 (1).webp"
import ag10 from "../assets/coupleFolders/aishwarya + ganapathi(wedding)/aish10 (1).webp"
import ag11 from "../assets/coupleFolders/aishwarya + ganapathi(wedding)/aish11 (1).webp"
import ag12 from "../assets/coupleFolders/aishwarya + ganapathi(wedding)/aish12 (1).webp"
import ag13 from "../assets/coupleFolders/aishwarya + ganapathi(wedding)/aish13 (1).webp"
import ag14 from "../assets/coupleFolders/aishwarya + ganapathi(wedding)/aish14 (1).webp"

const aishwaryaGanapathiWeddingData = [
  ag1,
  ag2,
  ag3,
  ag4,
  ag5,
  ag6,
  ag7,
  ag8,
  ag9,
  ag10,
  ag11,
  ag12,
  ag13,
  ag14,
];
window.addEventListener("scroll", reveal);

function reveal() {
  let reveals = document.querySelectorAll(".grid-item");

  for (let i = 0; i < reveals.length; i++) {
    let windowHeight = window.innerHeight;
    let revealTop = reveals[i].getBoundingClientRect().top;
    let revealPoint = 50; // Adjust this value according to when you want the animation to trigger

    if (revealTop < windowHeight - revealPoint) {
      reveals[i].classList.add("animated", "zoom-in");
      reveals[i].classList.remove("zoom-out");
    } else {
      reveals[i].classList.add("animated", "zoom-out");
      reveals[i].classList.remove("zoom-in");
    }
  }
}

export default function AishwaryaGanapathiWedding() {
  return (
    <>
      <div className="bg-[#f5f1eb] pb-[70px]">
        <div className="w-full h-[90px] bg-black"></div>
        <p className="xl:text-[40px] text-[20px] font-[ameralays] tracking-widest font-extrabold capitalize text-center mt-[50px] mb-[30px] xl:mt-[70px]">
          Aishwarya & Ganapathi
        </p>
        <p className="text-[#847f81] font-[BaskervilleRegular] text-[14px] text-center mt-[20px] xl:pl-[270px] xl:pr-[270px] xl:mb-[70px] pl-[15px] pr-[15px] mb-[50px]">
          Celebrate the sacred union of Aishwarya and Ganapathi in a beautifully
          traditional Brahmin wedding ceremony in Mysore. Our photographers captured
          every divine ritual and joyous moment, reflecting the deep cultural
          heritage and timeless love. Experience their special day through
          stunning images that bring the essence of their enchanting wedding to
          life.
        </p>

        <div className="flex flex-col xl:items-center gap-[20px] pl-[15px] pr-[15px] xl:pl-0 xl:pr-0">
          {aishwaryaGanapathiWeddingData.map((item, index) => (
            <div
              key={index}
              className="flex flex-col grid-item animated zoom-in "
            >
              <img
                src={item}
                loading="lazy"
                alt="reception photography"
                className="xl:w-[1000px] w-full h-full object-cover"
              />
            </div>
          ))}
        </div>

        <p className="xl:text-[30px] text-[20px] font-[services] font-extrabold capitalize text-center mt-[70px] xl:pl-0 xl:pr-0 pl-[15px] pr-[15px]">
          Can't wait to capture the moments with us ?
        </p>
        <div className="flex justify-center mt-[40px] pl-[15px] pr-[15px] xl:pl-0 xl:pr-0">
          <a href="/contact">
            {" "}
            <button className="bg-[#875239] p-[15px] w-[200px] text-[14px] font-semibold text-white hover:bg-[#ba9a8b] font-[BaskervilleRegular]">
              Book Us Now !
            </button>
          </a>
        </div>
      </div>
    </>
  );
}
