import pa1 from "../assets/coupleFolders/prashanth+akshitha(wedding)/pawed1 (1).webp";
import pa2 from "../assets/coupleFolders/prashanth+akshitha(wedding)/pawed2 (1).webp";
import pa3 from "../assets/coupleFolders/prashanth+akshitha(wedding)/pawed3 (1).webp";
import pa4 from "../assets/coupleFolders/prashanth+akshitha(wedding)/pawed4 (1).webp";
import pa5 from "../assets/coupleFolders/prashanth+akshitha(wedding)/pawed5 (1).webp";
import pa6 from "../assets/coupleFolders/prashanth+akshitha(wedding)/pawed6 (1).webp";
import pa7 from "../assets/coupleFolders/prashanth+akshitha(wedding)/pawed7 (1).webp";
import pa8 from "../assets/coupleFolders/prashanth+akshitha(wedding)/pawed8 (1).webp";
import pa9 from "../assets/coupleFolders/prashanth+akshitha(wedding)/pawed9 (1).webp";
import pa10 from "../assets/coupleFolders/prashanth+akshitha(wedding)/pawed10 (1).webp";
import pa11 from "../assets/coupleFolders/prashanth+akshitha(wedding)/pawed11 (1).webp";
import pa12 from "../assets/coupleFolders/prashanth+akshitha(wedding)/pawed12 (1).webp";
import pa13 from "../assets/coupleFolders/prashanth+akshitha(wedding)/pawed13 (1).webp";
import pa14 from "../assets/coupleFolders/prashanth+akshitha(wedding)/pawed14 (1).webp";
import pa15 from "../assets/coupleFolders/prashanth+akshitha(wedding)/pawed15 (1).webp";



const prashanthAkshithaweddingData = [
  pa1,
  pa2,
  pa3,
  pa4,
  pa5,
  pa6,
  pa7,
  pa8,
  pa9,
  pa10,
  pa11,
  pa12,
  pa13,
  pa14,
  pa15,
];

window.addEventListener("scroll", reveal);

function reveal() {
  let reveals = document.querySelectorAll(".grid-item");

  for (let i = 0; i < reveals.length; i++) {
    let windowHeight = window.innerHeight;
    let revealTop = reveals[i].getBoundingClientRect().top;
    let revealPoint = 50; // Adjust this value according to when you want the animation to trigger

    if (revealTop < windowHeight - revealPoint) {
      reveals[i].classList.add("animated", "zoom-in");
      reveals[i].classList.remove("zoom-out");
    } else {
      reveals[i].classList.add("animated", "zoom-out");
      reveals[i].classList.remove("zoom-in");
    }
  }
}

export default function PrashanthAkshithaWedding() {
  return (
    <>
      <div className="bg-[#f5f1eb] pb-[70px]">
        <div className="w-full h-[90px] bg-black"></div>
        <p className="xl:text-[40px] text-[20px] font-[ameralays] tracking-widest font-extrabold capitalize text-center mt-[50px] mb-[30px] xl:mt-[70px]">
          Prashanth & <span className="ml-[15px]">Akshitha</span>
        </p>
        <p className="text-[#847f81] font-[BaskervilleRegular] text-[14px] text-center mt-[20px] xl:pl-[270px] xl:pr-[270px] xl:mb-[20px] pl-[15px] pr-[15px] mb-[20px]">
          Celebrate the romantic union of Prashanth and Akshitha in their
          enchanting Gowda wedding. Filled with love and tradition, their
          special day radiated joy and beauty. Each moment captured their deep
          bond and the magic of their love story.
        </p>
        <p className="text-[#847f81] font-[BaskervilleRegular] text-[14px] text-center mt-[20px] xl:pl-[270px] xl:pr-[270px] xl:mb-[70px] pl-[15px] pr-[15px] mb-[50px] italic">
          Sira, Karnataka
        </p>

        <div className="flex flex-col xl:items-center gap-[20px] pl-[15px] pr-[15px] xl:pl-0 xl:pr-0">
          {prashanthAkshithaweddingData.map((item, index) => (
            <div
              key={index}
              className="flex flex-col grid-item animated zoom-in"
            >
              <img
                src={item}
                alt="personalized wedding services"
                loading="lazy"
                className="xl:w-[1000px] w-full h-full object-cover"
              />
            </div>
          ))}
        </div>

        <p className="xl:text-[30px] text-[20px] font-[services] font-extrabold capitalize text-center mt-[70px] xl:pl-0 xl:pr-0 pl-[15px] pr-[15px]">
          Can't wait to capture the moments with us ?
        </p>
        <div className="flex justify-center mt-[40px] pl-[15px] pr-[15px] xl:pl-0 xl:pr-0">
          <a href="/contact">
            {" "}
            <button className="bg-[#875239] p-[15px] w-[200px] text-[14px] font-semibold text-white hover:bg-[#ba9a8b] font-[BaskervilleRegular]">
              Book Us Now !
            </button>
          </a>
        </div>
      </div>
    </>
  );
}
