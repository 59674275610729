import as1 from "../../assets/coupleFolders/anusha+siddharth/1 (1) (1).webp";
import as2 from "../../assets/coupleFolders/anusha+siddharth/2 (1) (1).webp";
import as3 from "../../assets/coupleFolders/anusha+siddharth/3 (1) (1).webp";
import as4 from "../../assets/coupleFolders/anusha+siddharth/4 (1) (1).webp";
import as5 from "../../assets/coupleFolders/anusha+siddharth/5 (1) (1).webp";
import as6 from "../../assets/coupleFolders/anusha+siddharth/6 (1) (1).webp";
import as7 from "../../assets/coupleFolders/anusha+siddharth/7 (1) (1).webp";
import as8 from "../../assets/coupleFolders/anusha+siddharth/8 (1) (1).webp";
import as9 from "../../assets/coupleFolders/anusha+siddharth/9 (1) (1).webp";
import as10 from "../../assets/coupleFolders/anusha+siddharth/10 (1) (1).webp";
import as11 from "../../assets/coupleFolders/anusha+siddharth/11 (1) (1).webp";
import as12 from "../../assets/coupleFolders/anusha+siddharth/12 (1) (1).webp";


const anushaSiddhardthData=[as1,as2,as3,as4,as5,as6,as7,as8,as9,as10,as11,as12];

window.addEventListener("scroll", reveal);

function reveal() {
  let reveals = document.querySelectorAll(".grid-item");

  for (let i = 0; i < reveals.length; i++) {
    let windowHeight = window.innerHeight;
    let revealTop = reveals[i].getBoundingClientRect().top;
    let revealPoint = 50; // Adjust this value according to when you want the animation to trigger

    if (revealTop < windowHeight - revealPoint) {
      reveals[i].classList.add("animated", "zoom-in");
      reveals[i].classList.remove("zoom-out");
    } else {
      reveals[i].classList.add("animated", "zoom-out");
      reveals[i].classList.remove("zoom-in");
    }
  }
}

export default function AnushaSiddhardth() {
  return (
    <>
      <div className="bg-[#f5f1eb] pb-[70px]">
        <div className="w-full h-[90px] bg-black"></div>
        <p className="xl:text-[40px] mb-[50px] text-[20px] font-[ameralays] tracking-widest font-extrabold capitalize text-center mt-[50px] xl:mt-[70px]">
          Anusha & Siddhardth
        </p>
        <p className="text-[#847f81] font-[BaskervilleRegular] text-[14px] text-center mt-[40px] xl:pl-[200px] xl:pr-[200px] xl:mb-[70px] pl-[15px] pr-[15px] mb-[50px]">
          Anusha and Siddharth's haldi ceremony was a vibrant affair filled with
          laughter and joy. Amidst the aroma of turmeric and floral decorations,
          friends and family gathered to bless the couple with love and good
          wishes. The air echoed with cheerful music as golden hues adorned the
          venue, symbolizing purity and auspicious beginnings. It was a
          heartwarming celebration of tradition and togetherness, marking the
          joyful countdown to their wedding day.
        </p>

        <div className="flex flex-col xl:items-center gap-[20px] pl-[15px] pr-[15px] xl:pl-0 xl:pr-0">
          {anushaSiddhardthData.map((item, index) => (
            <div
              key={index}
              className="flex flex-col grid-item animated zoom-in"
            >
              <img
                src={item}
                alt="conceptual photography"
                loading="lazy"
                className="xl:w-[1000px] w-full h-full object-cover"
              />
            </div>
          ))}
        </div>

        <p className="xl:text-[30px] text-[20px] font-[services] font-extrabold capitalize text-center mt-[70px] xl:pl-0 xl:pr-0 pl-[15px] pr-[15px]">
          Can't wait to capture the moments with us ?
        </p>
        <div className="flex justify-center mt-[40px] pl-[15px] pr-[15px] xl:pl-0 xl:pr-0">
          <a href="/contact">
            {" "}
            <button className="bg-[#875239] p-[15px] w-[200px] text-[14px] font-semibold text-white hover:bg-[#ba9a8b] font-[BaskervilleRegular]">
              Book Us Now !
            </button>
          </a>
        </div>
      </div>
    </>
  );
}
