import ca1 from "../../assets/coupleFolders/chandu+anusha(wedding)/ca_11 (1).webp";
import ca2 from "../../assets/coupleFolders/chandu+anusha(wedding)/ca_22 (2).webp";
import ca3 from "../../assets/coupleFolders/chandu+anusha(wedding)/ca_33 (1).webp";
import ca4 from "../../assets/coupleFolders/chandu+anusha(wedding)/ca_44 (1).webp";
import ca5 from "../../assets/coupleFolders/chandu+anusha(wedding)/ca_55 (1).webp";
import ca6 from "../../assets/coupleFolders/chandu+anusha(wedding)/ca_66 (1).webp";
import ca7 from "../../assets/coupleFolders/chandu+anusha(wedding)/ca_77 (1).webp";
import ca8 from "../../assets/coupleFolders/chandu+anusha(wedding)/ca_88 (1).webp";
import ca9 from "../../assets/coupleFolders/chandu+anusha(wedding)/chandhuanusha2 (1).webp";
import ca10 from "../../assets/coupleFolders/chandu+anusha(wedding)/chandhuanusha4 (1).webp";
import ca11 from "../../assets/coupleFolders/chandu+anusha(wedding)/chnadhuanusha3 (2).webp";

const chanduAnushaWeddingData = [ca1, ca2, ca3, ca4, ca5, ca6, ca7, ca8,ca9,ca10,ca11];

window.addEventListener("scroll", reveal);

function reveal() {
  let reveals = document.querySelectorAll(".grid-item");

  for (let i = 0; i < reveals.length; i++) {
    let windowHeight = window.innerHeight;
    let revealTop = reveals[i].getBoundingClientRect().top;
    let revealPoint = 50; // Adjust this value according to when you want the animation to trigger

    if (revealTop < windowHeight - revealPoint) {
      reveals[i].classList.add("animated", "zoom-in");
      reveals[i].classList.remove("zoom-out");
    } else {
      reveals[i].classList.add("animated", "zoom-out");
      reveals[i].classList.remove("zoom-in");
    }
  }
}

export default function ChanduAnusha() {
  return (
    <>
      <div className="bg-[#f5f1eb] pb-[70px]">
        <div className="w-full h-[90px] bg-black"></div>
        <p className="xl:text-[40px] text-[20px] font-[ameralays] tracking-widest font-extrabold capitalize text-center mt-[50px] mb-[30px] xl:mt-[70px]">
          Chandu & <span className="ml-[15px]">Anusha</span>
        </p>
         <p className="text-[#847f81] font-[BaskervilleRegular] text-[14px] text-center mt-[20px] xl:pl-[270px] xl:pr-[270px] xl:mb-[20px] pl-[15px] pr-[15px] mb-[20px]">
          Experience the vibrant and heartfelt union of Chandu and Anusha in
          their beautiful Gowda wedding. Their celebration was filled with rich
          traditions, joyous moments, and love that radiated in every smile and
          ritual. Each image captures the essence of their unique bond and
          cultural heritage. Join us in reliving their magical day, where
          tradition met love in perfect harmony.
        </p>
        <p className="text-[#847f81] font-[BaskervilleRegular] text-[14px] text-center mt-[20px] xl:pl-[270px] xl:pr-[270px] xl:mb-[70px] pl-[15px] pr-[15px] mb-[50px] italic">
          Bangalore, India
        </p>
        <div className="flex flex-col xl:items-center gap-[20px] pl-[15px] pr-[15px] xl:pl-0 xl:pr-0">
          {chanduAnushaWeddingData.map((item, index) => (
            <div
              key={index}
              className="flex flex-col grid-item animated zoom-in "
            >
              <img
                src={item}
                alt="couple intimate shoots"
                loading="lazy"
                className="xl:w-[1000px] w-full h-full object-cover"
              />
            </div>
          ))}
        </div>

        <p className="xl:text-[30px] text-[20px] font-[services] font-extrabold capitalize text-center mt-[70px] xl:pl-0 xl:pr-0 pl-[15px] pr-[15px]">
          Can't wait to capture the moments with us ?
        </p>
        <div className="flex justify-center mt-[40px] pl-[15px] pr-[15px] xl:pl-0 xl:pr-0">
          <a href="/contact">
            {" "}
            <button className="bg-[#875239] p-[15px] w-[200px] text-[14px] font-semibold text-white hover:bg-[#ba9a8b] font-[BaskervilleRegular]">
              Book Us Now !
            </button>
          </a>
        </div>
      </div>
    </>
  );
}
