import ReactPlayer from "react-player";
import weddingphotography from "../assets/pictures/blogs/serviceswedding.webp";
import wedding from "../assets/pictures/services/weddingphotography (1).webp";
import prewedding from "../assets/pictures/services/prewedding_2222 1.webp";
import engagement from "../assets/pictures/services/engagementphotography (1).webp";
import haldi from "../assets/pictures/services/rn2 (1) (1).webp";
import mehendi from "../assets/pictures/services/mehendiphotography (1).webp";
import sangeet from "../assets/pictures/services/12345.webp";
import reception from "../assets/pictures/services/receptionphotography (1).webp";
import traditional from "../assets/pictures/services/CBI_8965 2 1 (1).webp";
import candid from "../assets/pictures/services/candidphotography (1).webp";
import concept from "../assets/pictures/services/conceptualphotography (1).webp";
import premiumalbums from "../assets/pictures/services/premium albums.webp";
import thalagiri from "../assets/pictures/services/thalagiri.webp";
import Call from "../components/Call";
import DesktopWa from "../components/DesktopWa";
import { cls } from "../components/Utility";

const servicesData = [
  {
    imgsrc: wedding,
    title: "Wedding photography",
    description1: `  we capture the magic of your wedding day 
    with beautiful, high-quality photos. Our skilled photographers
     focus on every special moment, from loving glances to joyful celebrations.
      Using the best equipment, we ensure your memories are preserved perfectly. `,
    description2: `Trust us to create stunning images you’ll cherish forever, 
    making your big day unforgettable. Experience the difference with our 
    personalized and exceptional service.`,
  },
  {
    imgsrc: prewedding,
    title: "Pre-Wedding photography",
    description1: `we offer exceptional pre-wedding photography that tells your
              unique love story. Whether it's a breathtaking destination shoot
              or a highly conceptual theme, our talented photographers capture
              your connection in stunning, creative images.  `,
    description2: `Each photo highlights the special bond you share and the joy of
              your journey together. Experience high-quality photos that you'll
              cherish forever, celebrating your love in unforgettable and
              artistic ways.`,
  },
  {
    imgsrc: engagement,
    title: "Engagement photography",
    description1: `Capture the joy and excitement of your engagement with Stories by
              RG . Our skilled photographers create beautiful, unique images
              that showcase your love and happiness. From candid moments to
              stunning poses, every photo reflects your special bond.`,
    description2: `Celebrate this milestone with breathtaking photos you'll
              always cherish. Let us turn your engagement into a visual journey
              of love.`,
  },
  {
    imgsrc: haldi,
    title: "Haldi Photography",
    description1: ` Experience the vibrant colors and
     joyful moments of your Haldi ceremony. Our skilled
      photographers expertly preserve every laughter-filled, 
      turmeric-smeared moment with creativity and care. 
      From candid captures to beautiful portraits, each photo embodies 
      the joy and excitement of this cherished ritual.`,
    description2: `Celebrate your Haldi with stunning images
     that you'll cherish forever. Let us transform your Haldi
      ceremony into a vivid visual narrative.`,
  },
  {
    imgsrc: mehendi,
    title: "Mehandi Photography",
    description1: ` Immerse yourself in the intricate beauty and cultural richness of your Mehandi ceremony 
    with Stories by RG. Our skilled photographers expertly 
    capture every detail and joyous moment with precision and creativity.
     From close-ups of intricate henna designs to candid snapshots of laughter and celebration,
      each photograph vividly portrays the essence of this cherished tradition.`,
    description2: `Celebrate your Mehandi with captivating images that capture the vibrancy and emotion of the occasion.
     Let us craft a visual narrative that beautifully tells the story of your Mehandi ceremony.`,
  },

  {
    imgsrc: sangeet,
    title: "Sangeet photography",
    description1: `Celebrate the rhythm and joy of your Sangeet ceremony . 
    Our skilled photographers capture every dance move and heartfelt moment with creativity and expertise.
     From lively group shots to intimate portraits, 
     each photograph preserves the energy and spirit of this festive occasion.`,
    description2: `Embrace the music and laughter with stunning images that tell the story of your Sangeet 
    in a unique and vibrant way. Let us document your celebration with photos
     that bring the joy of your Sangeet to life`,
  },
  {
    imgsrc: reception,
    title: "Reception photography",
    description1: ` Witness the elegance and celebration of your reception through the 
    lens of Stories by RG. Our talented photographers expertly capture every enchanting moment
     with finesse and creativity. From heartfelt embraces to vibrant dance floor festivities,
      each photograph narrates the essence of your unforgettable day. Embrace the joy and glamour
     with captivating images that immortalize your love story.
`,
    description2: `Let us craft a timeless visual tale of your reception, ensuring every detail shines brightly.
    `,
  },
  {
    imgsrc: traditional,
    title: "Traditional photography",
    description1: ` Experience the richness and cultural heritage of your event through the
     lens of Stories by RG. Our skilled photographers expertly capture the essence and timeless 
     beauty of each traditional ritual and celebration.`,
    description2: `From symbolic gestures to cherished customs,
     every photograph preserves the authenticity and significance of your special occasion. 
     Celebrate the heritage and spirit with stunning images that capture the heart of your traditions. 
     Let us document your event with photos that honor the depth and meaning of your traditions.`,
  },

  {
    imgsrc: concept,
    title: "Concept photography",
    description1: ` Experience creative storytelling through our lens. We turn ideas
     into captivating visuals that reflect your unique style
    . From imaginative themes to heartfelt concepts, 
    each photo captures your vision with clarity and creativity.`,
    description2: `Celebrate personal expression with images that redefine traditional photography. 
    Let us bring your ideas to life with artistic storytelling that leaves a lasting impression.`,
  },
  {
    imgsrc: candid,
    title: "Candid photography",
    description1: ` Capture genuine moments with Stories by RG. Our photographers specialize in spontaneous, 
    natural shots that reflect the true essence of your special day. From heartfelt
    smiles to joyful tears, each photograph preserves the authentic emotions and spontaneity of your event.`,
    description2: `Celebrate the beauty of unposed moments with images that tell your story in a heartfelt
     and genuine way. Let us document your day with candid photography that captures memories you'll cherish forever.`,
  },

  {
    imgsrc: premiumalbums,
    title: "premium albums",
    description1: ` Enhance your cherished memories with Stories by RG's premium albums. 
    Crafted with quality materials and attention to detail,
     our albums are designed to preserve your special moments beautifully.`,
    description2: `Each page tells a story, capturing the emotions and highlights
     of your day with elegance and sophistication.
      Celebrate your memories in style with albums that are as
     timeless as your love. Let us create a lasting
      keepsake that you'll treasure for years to come.`,
  },
  {
    imgsrc: weddingphotography,
    title: "potraits",
    description1: `Capture your essence with Stories by RG's portrait photography. 
    Our skilled photographers specialize in creating timeless and striking portraits
     that reflect your unique personality and style. Whether it's a solo portrait or a group shot,
      each photograph is crafted with care to highlight your best features and emotions.`,
    description2: `Celebrate your individuality with portraits that capture the beauty and spirit of the moment. 
    Let us create stunning images that you'll proudly display and cherish for years to come.`,
  },

  {
    imgsrc: thalagiri,
    title: "Thalagiri Albums",
    description1: ` Discover the elegance of tradition with Stories by RG's
              Thalagiri Albums. Meticulously crafted from premium materials,
              these albums beautifully showcase your most cherished memories.
              Each page captures the essence of your special moments, blending
              cultural richness with modern sophistication. Celebrate your
              unique journey with an album that turns memories into an
              exquisite, timeless keepsake. `,
    description2: `Let us create a stunning visual
              story that you’ll treasure for generations.`,
  },
];

const vedioData = [
  "https://www.youtube.com/watch?v=s4-FB2D7gPM&t=1s",
  "https://www.youtube.com/watch?v=IMAxe_lEulA",
  "https://www.youtube.com/watch?v=p3NL3XPTYiM",
  "https://www.youtube.com/watch?v=ffc_ikHEZpM",
];

export default function Services() {
  return (
    <div className="bg-[#f5f1eb] xl:mt-[0px] pb-[30px] relative">
      <div className="w-full xl:h-[300px] xl:mb-[70px] mb-[50px] bg-black flex flex-col xl:pl-[150px] xl:pr-[150px] xl:pb-0 xl:pt-[50px] pl-[20px] pr-[20px] pb-[20px] pt-0">
        <p className="font-bold xl:text-[40px] text-[20px] text-white font-[ameralays] tracking-widest mt-[120px] text-center">
          Explore Our Services
        </p>

        <p className="font-bold text-[14px] text-white font-[BaskervilleRegular] mt-[30px] text-center ">
          Dive into our services that make us stand out with exceptional quality
          and unparalleled excellence
        </p>
      </div>

      <div
        className={cls(
          "hidden",
          "xl:flex xl:flex-col xl:gap-[70px] xl:items-center"
        )}
      >
        <div className="flex gap-[70px]">
          <img
            className="w-[600px] h-[400px] object-cover"
            src={wedding}
            alt="best innovative wedding photography"
            loading="lazy"
          />
          <div className="flex flex-col gap-[40px] w-[500px]">
            <p className="text-[40px] font-[services] capitalize">
              Wedding Photography
            </p>
            <p className="text-[#847f81] font-[BaskervilleRegular] text-[14px] mt-[20px]">
              we capture the magic of your wedding day with beautiful,
              high-quality photos. Our skilled photographers focus on every
              special moment, from loving glances to joyful celebrations. Using
              the best equipment, we ensure your memories are preserved
              perfectly.
              <br />
              <br /> Trust us to create stunning images you’ll cherish forever,
              making your big day unforgettable. Experience the difference with
              our personalized and exceptional service.
            </p>
          </div>
        </div>

        <div className="flex gap-[70px]">
          <div className="flex flex-col gap-[40px] w-[500px]">
            <p className="text-[40px] font-[services] capitalize">
              Pre-Wedding Photography
            </p>
            <p className="text-[#847f81] font-[BaskervilleRegular] text-[14px] mt-[20px]">
              we offer exceptional pre-wedding photography that tells your
              unique love story. Whether it's a breathtaking destination shoot
              or a highly conceptual theme, our talented photographers capture
              your connection in stunning, creative images.
              <br />
              <br />
              Each photo highlights the special bond you share and the joy of
              your journey together. Experience high-quality photos that you'll
              cherish forever, celebrating your love in unforgettable and
              artistic ways.
            </p>
          </div>
          <img
            className="w-[600px] h-[400px] object-cover"
            src={prewedding}
            alt="best innovative wedding photography"
            loading="lazy"
          />
        </div>
        <div className="flex gap-[70px]">
          <img
            className="w-[600px] h-[400px] object-cover"
            src={engagement}
            alt="best innovative wedding photography"
            loading="lazy"
          />
          <div className="flex flex-col gap-[40px] w-[500px]">
            <p className="text-[40px] font-[services] capitalize">
              Engagement Photography
            </p>
            <p className="text-[#847f81] font-[BaskervilleRegular] text-[14px] mt-[20px]">
              Capture the joy and excitement of your engagement with Stories by
              RG . Our skilled photographers create beautiful, unique images
              that showcase your love and happiness. From candid moments to
              stunning poses, every photo reflects your special bond.
              <br />
              <br /> Celebrate this milestone with breathtaking photos you'll
              always cherish. Let us turn your engagement into a visual journey
              of love.
            </p>
          </div>
        </div>
        <div className="flex gap-[70px]">
          <div className="flex flex-col gap-[40px] w-[500px]">
            <p className="text-[40px] font-[services] capitalize">
              Haldi Photography
            </p>
            <p className="text-[#847f81] font-[BaskervilleRegular] text-[14px] mt-[20px]">
              Experience the vibrant colors and joyful moments of your Haldi
              ceremony. Our skilled photographers expertly preserve every
              laughter-filled, turmeric-smeared moment with creativity and care.
              From candid captures to beautiful portraits, each photo embodies
              the joy and excitement of this cherished ritual.
              <br />
              <br /> Celebrate your Haldi with stunning images that you'll
              cherish forever. Let us transform your Haldi ceremony into a vivid
              visual narrative.
            </p>
          </div>
          <img
            className="w-[600px] h-[400px] object-cover"
            src={haldi}
            alt="best innovative wedding photography"
            loading="lazy"
          />
        </div>
        <div className="flex gap-[70px]">
          <img
            className="w-[600px] h-[400px] object-cover"
            src={mehendi}
            alt="best innovative wedding photography"
            loading="lazy"
          />
          <div className="flex flex-col gap-[40px] w-[500px]">
            <p className="text-[40px] font-[services] capitalize">
              Mehandi Photography
            </p>
            <p className="text-[#847f81] font-[BaskervilleRegular] text-[14px] mt-[20px]">
              Immerse yourself in the intricate beauty and cultural richness of
              your Mehandi ceremony with Stories by RG. Our skilled
              photographers expertly capture every detail and joyous moment with
              precision and creativity. From close-ups of intricate henna
              designs to candid snapshots of laughter and celebration, each
              photograph vividly portrays the essence of this cherished
              tradition.
              <br />
              <br /> Celebrate your Mehandi with captivating images that capture
              the vibrancy and emotion of the occasion. Let us craft a visual
              narrative that beautifully tells the story of your Mehandi
              ceremony.
            </p>
          </div>
        </div>
        <div className="flex gap-[70px]">
          <div className="flex flex-col gap-[40px] w-[500px]">
            <p className="text-[40px] font-[services] capitalize">
              Sangeet Photography
            </p>
            <p className="text-[#847f81] font-[BaskervilleRegular] text-[14px] mt-[20px]">
              Celebrate the rhythm and joy of your Sangeet ceremony . Our
              skilled photographers capture every dance move and heartfelt
              moment with creativity and expertise. From lively group shots to
              intimate portraits, each photograph preserves the energy and
              spirit of this festive occasion.
              <br />
              <br /> Embrace the music and laughter with stunning images that
              tell the story of your Sangeet in a unique and vibrant way. Let us
              document your celebration with photos that bring the joy of your
              Sangeet to life.
            </p>
          </div>
          <img
            className="w-[600px] h-[400px] object-cover"
            src={sangeet}
            alt="best innovative wedding photography"
            loading="lazy"
          />
        </div>

        <div className="flex gap-[70px]">
          <img
            className="w-[600px] h-[400px] object-cover"
            src={reception}
            alt="best innovative wedding photography"
            loading="lazy"
          />
          <div className="flex flex-col gap-[40px] w-[500px]">
            <p className="text-[40px] font-[services] capitalize">
              Reception Photography
            </p>
            <p className="text-[#847f81] font-[BaskervilleRegular] text-[14px] mt-[20px]">
              Witness the elegance and celebration of your reception through the
              lens of Stories by RG. Our talented photographers expertly capture
              every enchanting moment with finesse and creativity. From
              heartfelt embraces to vibrant dance floor festivities, each
              photograph narrates the essence of your unforgettable day. Embrace
              the joy and glamour with captivating images that immortalize your
              love story.
              <br />
              <br />
              Let us craft a timeless visual tale of your reception, ensuring
              every detail shines brightly.
            </p>
          </div>
        </div>
        <div className="flex gap-[70px]">
          <div className="flex flex-col gap-[40px] w-[500px]">
            <p className="text-[40px] font-[services] capitalize">
              Traditional Photography
            </p>
            <p className="text-[#847f81] font-[BaskervilleRegular] text-[14px] mt-[20px]">
              Experience the richness and cultural heritage of your event
              through the lens of Stories by RG. Our skilled photographers
              expertly capture the essence and timeless beauty of each
              traditional ritual and celebration.
              <br />
              <br /> From symbolic gestures to cherished customs, every
              photograph preserves the authenticity and significance of your
              special occasion. Celebrate the heritage and spirit with stunning
              images that capture the heart of your traditions. Let us document
              your event with photos that honor the depth and meaning of your
              traditions.
            </p>
          </div>
          <img
            className="w-[600px] h-[400px] object-cover"
            src={traditional}
            alt="best innovative wedding photography"
            loading="lazy"
          />
        </div>
        <div className="flex gap-[70px]">
          <img
            className="w-[600px] h-[400px] object-cover"
            src={concept}
            alt="best innovative wedding photography"
            loading="lazy"
          />
          <div className="flex flex-col gap-[40px] w-[500px]">
            <p className="text-[40px] font-[services] capitalize">
              Concept Photography
            </p>
            <p className="text-[#847f81] font-[BaskervilleRegular] text-[14px] mt-[20px]">
              Experience creative storytelling through our lens. We turn ideas
              into captivating visuals that reflect your unique style. From
              imaginative themes to heartfelt concepts, each photo captures your
              vision with clarity and creativity.
              <br />
              <br /> Celebrate personal expression with images that redefine
              traditional photography. Let us bring your ideas to life with
              artistic storytelling that leaves a lasting impression.
            </p>
          </div>
        </div>
        <div className="flex gap-[70px]">
          <div className="flex flex-col gap-[40px] w-[500px]">
            <p className="text-[40px] font-[services] capitalize">
              Candid Photography
            </p>
            <p className="text-[#847f81] font-[BaskervilleRegular] text-[14px] mt-[20px]">
              Capture genuine moments with Stories by RG. Our photographers
              specialize in spontaneous, natural shots that reflect the true
              essence of your special day. From heartfelt smiles to joyful
              tears, each photograph preserves the authentic emotions and
              spontaneity of your event.
              <br />
              <br /> Celebrate the beauty of unposed moments with images that
              tell your story in a heartfelt and genuine way. Let us document
              your day with candid photography that captures memories you'll
              cherish forever.
            </p>
          </div>
          <img
            className="w-[600px] h-[400px] object-cover"
            src={candid}
            alt="best innovative wedding photography"
            loading="lazy"
          />
        </div>

        <div className="flex gap-[70px]">
          <img
            className="w-[600px] h-[400px] object-cover"
            src={premiumalbums}
            alt="best innovative wedding photography"
            loading="lazy"
          />
          <div className="flex flex-col gap-[40px] w-[500px]">
            <p className="text-[40px] font-[services] capitalize">
              Premium Albums
            </p>
            <p className="text-[#847f81] font-[BaskervilleRegular] text-[14px] mt-[20px]">
              Enhance your cherished memories with Stories by RG's premium
              albums. Crafted with quality materials and attention to detail,
              our albums are designed to preserve your special moments
              beautifully.
              <br />
              <br /> Each page tells a story, capturing the emotions and
              highlights of your day with elegance and sophistication. Celebrate
              your memories in style with albums that are as timeless as your
              love. Let us create a lasting keepsake that you'll treasure for
              years to come.
            </p>
          </div>
        </div>

        <div className="flex gap-[70px]">
          <div className="flex flex-col gap-[40px] w-[500px]">
            <p className="text-[40px] font-[services] capitalize">Potraits</p>
            <p className="text-[#847f81] font-[BaskervilleRegular] text-[14px] mt-[20px]">
              Capture your essence with Stories by RG's portrait photography.
              Our skilled photographers specialize in creating timeless and
              striking portraits that reflect your unique personality and style.
              Whether it's a solo portrait or a group shot, each photograph is
              crafted with care to highlight your best features and emotions.
              <br />
              <br />
              Celebrate your individuality with portraits that capture the
              beauty and spirit of the moment. Let us create stunning images
              that you'll proudly display and cherish for years to come.
            </p>
          </div>
          <img
            className="w-[600px] h-[400px] object-cover"
            src={weddingphotography}
            alt="best innovative wedding photography"
            loading="lazy"
          />
        </div>

        <div className="flex gap-[70px]">
          <img
            className="w-[600px] h-[400px] object-cover"
            src={thalagiri}
            alt="best innovative wedding photography"
            loading="lazy"
          />
          <div className="flex flex-col gap-[40px] w-[500px]">
            <p className="text-[40px] font-[services] capitalize">
              Thalagiri Albums
            </p>
            <p className="text-[#847f81] font-[BaskervilleRegular] text-[14px] mt-[20px]">
              Discover the elegance of tradition with Stories by RG's
              Thalagiri Albums. Meticulously crafted from premium materials,
              these albums beautifully showcase your most cherished memories.
              Each page captures the essence of your special moments, blending
              cultural richness with modern sophistication. Celebrate your
              unique journey with an album that turns memories into an
              exquisite, timeless keepsake. 
              <br />
              <br /> Let us create a stunning visual
              story that you’ll treasure for generations.
            </p>
          </div>
        </div>
      </div>

      <div
        className={cls(
          "flex flex-col gap-[50px] pl-[15px] pr-[15px]",
          "xl:hidden"
        )}
      >
        {servicesData.map((item, index) => (
          <div key={index} className="flex flex-col ">
            <img
              src={item.imgsrc}
              loading="lazy"
              alt="wedding photography services"
              className="w-full h-[300px] object-cover"
            />
            <p className="text-[30px] font-[services] capitalize mt-[30px]">
              {item.title}
            </p>
            <p className="text-[#847f81] font-[BaskervilleRegular] text-[14px] mt-[20px]">
              {item.description1}
            </p>
            <p className="text-[#847f81] font-[BaskervilleRegular] text-[14px] mt-[10px]">
              {item.description2}
            </p>
          </div>
        ))}
      </div>

      <p className="font-[ameralays] font-bold text-[20px] xl:text-[35px] text-center mt-[50px] xl:mt-[70px] tracking-widest">
        Cinematic Films
      </p>

      <p className="text-[#847f81] font-[BaskervilleRegular] text-[14px] mt-[30px] text-center pl-[15px] pr-[15px] xl:pl-[200px] xl:pr-[200px]">
        Watch the adorable moments of beautiful couples where love vibes in the
        big day celebrations. Here you can find the traditional,candid and
        concept videography which shows the excellence in each and every frame
        of love.
      </p>

      <div className="flex justify-center items-center">
        <div
          className={cls(
            " grid grid-cols-1 gap-[20px] mt-[50px] mb-[50px]",
            "xl:hidden"
          )}
        >
          {vedioData.map((item, index) => (
            <div key={index}>
              <ReactPlayer
                url={item}
                volume={1}
                width={350}
                height={200}
                playing={false}
                controls={true}
              />
            </div>
          ))}
        </div>{" "}
      </div>

      <div className="hidden xl:flex xl:justify-center xl:items-center mt-[70px] mb-[70px]">
        <div
          className={cls(
            "hidden",
            "xl:pl-[25px] xl:pr-[25px] xl:grid xl:grid-cols-2 xl:gap-[0.8px]"
          )}
        >
          {vedioData.map((item, index) => (
            <div key={index}>
              <ReactPlayer
                url={item}
                width={600}
                height={400}
                playing={false}
                controls={true}
                volume={1}
              />
            </div>
          ))}
        </div>
      </div>

      <div className="mt-[20px] text-center mb-[20px]">
        <p className="font-semibold xl:text-[40px] text-[25px] text-[#875239] mb-[10px] ">
          Call for free consultation at
        </p>
        <div className="font-semibold xl:text-[40px] text-[25px] text-[#875239] mb-[10px] ">
          <Call phoneNumber="+91 9353624245" />
        </div>
      </div>
      <div className="chat-icon">
        <DesktopWa />
      </div>
    </div>
  );
}
