
import DesktopWa from "../components/DesktopWa";
import { Helmet } from "react-helmet";
import { CgLink } from "react-icons/cg";
import all_3 from "../assets/pictures/portfolio/aishwaryaganapathi (2).webp";
import all7 from "../assets/pictures/portfolio/kshirajawahar (1).webp";
import all_10 from "../assets/coupleFolders/pavithra+nischal(wedding)/pn11 (1).webp";
import all18 from "../assets/pictures/portfolio/akashyashashwini (1).webp";
import all_9 from "../assets/pictures/portfolio/spoorthiindresh (1).webp";
import all_5 from "../assets/pictures/portfolio/PrashanthAkshithawedding (1).webp";
import all_1 from "../assets/coupleFolders/chandu+anusha(wedding)/ca_88 (1).webp";
import all_2 from "../assets/coupleFolders/ashwin+nischitha(pre-wedding)/ashwinnischitha3 (1).webp";
import all_6 from "../assets/coupleFolders/rahul+manasa(pre-wedding)/rahulmanasa1 (1).webp";
import all_7 from "../assets/coupleFolders/grisha+anirudh(pre-wedding)/grishaanirudh1 (1).webp";
import all_11 from "../assets/coupleFolders/anusha+siddharth/11 (1) (1).webp";

const allData = [
  {
    imgsrc: all_1,
    link: "/chandu-anusha-gowda-wedding",
    coupleName: "Chandu & Anusha",
  },
  {
    link:"/rahul-manasa-pre-wedding",
    imgsrc: all_6,
    coupleName: "Rahul & Manasa",
  },
  {
    link:"/prashanth-akshitha-wedding",
    imgsrc: all_5,
    coupleName: "Prashanth & Akshitha",
  },
  
  {
    imgsrc: all_2,
    link: "/ashwin-nischitha-pre-wedding",
    coupleName: "Ashwin & Nischitha",
  },
  {
    link: "/aishwarya-ganapathi-wedding",
    imgsrc: all_3,
    coupleName: "Aishwarya & Ganapathi",
  },
  {
    link:"/grisha-anirudh-pre-wedding",
    imgsrc: all_7,
    coupleName: "Grisha & Anirudh",
  },

  {
    link:"/spoorthi-indresh-wedding",
    imgsrc: all_9,
    coupleName: "Spoorthi & Indresh",
  },
  {
    link:'/pavithra-nischal-wedding',
    imgsrc: all_10,
    coupleName: "Pavithra & Nischal",
  },
  {
    link:"/kshira-jawahar-pre-wedding",
    imgsrc: all7,
    coupleName: "Kshira & Jawahar",
  },
  {
    link:"/akash-yashashwini-pre-wedding",
    imgsrc: all18,
    coupleName: "Akash & Yashashwini",
  },
 
  {
    link:'/anusha-siddhardth-haldi',
    imgsrc: all_11,
    coupleName: "Anusha & Siddhardth",
  },
 
];
export default function Portfolio() {

  return (
    <>
      <Helmet>
        <script type="application/ld+json">
          {`
        {
          "@context": "https://schema.org",
          "@type": "Organization",
          "name": "Stories by RG",
          "url": "https://www.storiesbyrg.com/portfolio",
          "description": "Welcome to our wedding portfolio, where love stories come to life. Here, we showcase a collection of our most cherished moments, beautiful designs, and unforgettable celebrations. Each wedding is a unique testament to our commitment to excellence and our passion for creating magical experiences."
        }
      `}
        </script>
      </Helmet>

      <div className="portfolio-page bg-[#f5f1eb] xl:mt-[0px] pb-[65px] relative">
        <div className="bg-black w-full h-[90px] xl:mb-[70px] mb-[50px]"></div>
        
        <div className="xl:flex xl:flex-col xl:items-center xl:gap-[70px] hidden">
        {allData.map((item, index) => (
          <div key={index} className="flex flex-col ">
            <a href={item.link}>
              <div className="image-containerall ">
                <img
                  src={item.imgsrc}
                  alt="Recent Imageall"
                  className="recent-imageall "
                />
                <div className="overlayall">
                  <p className="text-[30px] font-bold font-[ameralays] text-center tracking-widest">
                    {item.coupleName}
                  </p>

                  <div className="flex justify-center">
                    <button className="w-[50px] h-[50px] rounded-full bg-[#875239] hover:bg-[#dab4a3] p-[10px] text-white font-semibold text-[14px] font-[BaskervilleRegular] mt-[30px]">
                    <CgLink
                        size={26}
                        className="text-white font-bold ml-[1px]"
                      />
                    </button>
                  </div>
                </div>
              </div>
            </a>
          </div>
        ))}
      </div>


      <div className="flex flex-col gap-[40px]  pl-[15px] pr-[15px] xl:hidden">
        {allData.map((item, index) => (
          <div key={index} className="flex flex-col ">
           
              <div className="image-containerall1 ">
                <img
                  src={item.imgsrc}
                  alt="Recent Imageall1"
                  className="recent-imageall1"
                />
                <div className="overlayall1">
                  <p className="text-[18px] font-bold font-[ameralays] text-center tracking-wide">
                    {item.coupleName}
                  </p>

                  <a href={item.link}>  <div className="flex justify-center">
                    <button className="w-[50px] h-[50px] rounded-full bg-[#875239] hover:bg-[#dab4a3] p-[10px] text-white font-semibold text-[14px] font-[BaskervilleRegular] mt-[30px]">
                    <CgLink
                        size={26}
                        className="text-white font-bold ml-[1px]"
                      />
                    </button>
                  </div>  </a>
                </div>
              </div>
          
          </div>
        ))}
      </div>

       






        

        <p className="xl:text-[30px] text-[20px] font-[services] font-extrabold capitalize text-center mt-[70px] xl:pl-0 xl:pr-0 pl-[15px] pr-[15px]">
          Can't wait to capture the moments with us ?
        </p>
        <div className="flex justify-center mt-[40px] pl-[15px] pr-[15px] xl:pl-0 xl:pr-0">
        <a href="/contact"> <button className="bg-[#875239] p-[15px] w-[200px] text-[14px] font-semibold text-white hover:bg-[#ba9a8b] font-[BaskervilleRegular]">
            Book Us Now !
          </button></a>
        </div>


      </div>

      <div className="chat-icon">
        <DesktopWa />
      </div>
    </>
  );
}
