import ts1 from "../assets/coupleFolders/teena+sai(pre-wedding)/ts1 (1).webp";
import ts2 from "../assets/coupleFolders/teena+sai(pre-wedding)/ts2 (1).webp";
import ts3 from "../assets/coupleFolders/teena+sai(pre-wedding)/ts3 (1).webp";
import ts4 from "../assets/coupleFolders/teena+sai(pre-wedding)/ts4 (1).webp";
import ts5 from "../assets/coupleFolders/teena+sai(pre-wedding)/ts5 (1).webp";
import ts6 from "../assets/coupleFolders/teena+sai(pre-wedding)/ts6 (1).webp";
import ts7 from "../assets/coupleFolders/teena+sai(pre-wedding)/ts7 (1).webp";
import ts8 from "../assets/coupleFolders/teena+sai(pre-wedding)/ts8 (1).webp";
import ts9 from "../assets/coupleFolders/teena+sai(pre-wedding)/ts9 (1).webp";
import ts10 from "../assets/coupleFolders/teena+sai(pre-wedding)/ts10 (2).webp";
import ts11 from "../assets/coupleFolders/teena+sai(pre-wedding)/ts11 (1).webp";
import ts12 from "../assets/coupleFolders/teena+sai(pre-wedding)/ts12 (1).webp";
import ts13 from "../assets/coupleFolders/teena+sai(pre-wedding)/ts13 (1).webp";
import ts14 from "../assets/coupleFolders/teena+sai(pre-wedding)/ts14 (1).webp";
import ts15 from "../assets/coupleFolders/teena+sai(pre-wedding)/ts15 (1).webp";
import ts16 from "../assets/coupleFolders/teena+sai(pre-wedding)/ts16 (1).webp";
import ts17 from "../assets/coupleFolders/teena+sai(pre-wedding)/ts17 (1).webp";



const teenaSaiPreweddingdata = [
  ts1,
  ts2,
  ts3,
  ts4,
  ts5,
  ts6,
  ts7,
  ts8,
  ts9,
  ts10,
  ts11,
  ts12,
  ts13,
  ts14,
  ts15,
  ts16,
  ts17,
];

window.addEventListener("scroll", reveal);

function reveal() {
  let reveals = document.querySelectorAll(".grid-item");

  for (let i = 0; i < reveals.length; i++) {
    let windowHeight = window.innerHeight;
    let revealTop = reveals[i].getBoundingClientRect().top;
    let revealPoint = 50; // Adjust this value according to when you want the animation to trigger

    if (revealTop < windowHeight - revealPoint) {
      reveals[i].classList.add("animated", "zoom-in");
      reveals[i].classList.remove("zoom-out");
    } else {
      reveals[i].classList.add("animated", "zoom-out");
      reveals[i].classList.remove("zoom-in");
    }
  }
}

export default function TeenaSai() {
  return (
    <>
      <div className="bg-[#f5f1eb] pb-[70px]">
        <div className="w-full h-[90px] bg-black"></div>
        <p className="xl:text-[40px] text-[20px] mb-[50px] font-[ameralays] tracking-widest font-extrabold capitalize text-center mt-[50px] xl:mt-[70px]">
          Teena & Sai
        </p>
        <p className="text-[#847f81] font-[BaskervilleRegular] text-[14px] text-center mt-[40px] xl:pl-[200px] xl:pr-[200px] xl:mb-[70px] pl-[15px] pr-[15px] mb-[50px]">
          Teena and Sai's prewedding in Dubai paints a picture of love against
          the backdrop of opulence and charm. Their photos capture the essence
          of their journey, blending elegance with the vibrant spirit of the
          city. Dive into their unique love story through these captivating
          moments.
        </p>

        <div className="flex flex-col xl:items-center gap-[20px] pl-[15px] pr-[15px] xl:pl-0 xl:pr-0">
          {teenaSaiPreweddingdata.map((item, index) => (
            <div
              key={index}
              className="flex flex-col grid-item animated zoom-in "
            >
              <img
                src={item}
                loading="lazy"
                alt="luxury celebrity shoots"
                className="xl:w-[1000px] w-full h-full object-cover"
              />
            </div>
          ))}
        </div>

        <p className="xl:text-[30px] text-[20px] font-[services] font-extrabold capitalize text-center mt-[70px] xl:pl-0 xl:pr-0 pl-[15px] pr-[15px]">
          Can't wait to capture the moments with us ?
        </p>
        <div className="flex justify-center mt-[40px] pl-[15px] pr-[15px] xl:pl-0 xl:pr-0">
          <a href="/contact">
            {" "}
            <button className="bg-[#875239] p-[15px] w-[200px] text-[14px] font-semibold text-white hover:bg-[#ba9a8b] font-[BaskervilleRegular]">
              Book Us Now !
            </button>
          </a>
        </div>
      </div>
    </>
  );
}
