import pn1 from "../assets/coupleFolders/pavithra+nischal(wedding)/pn1 (1).webp";
import pn2 from "../assets/coupleFolders/pavithra+nischal(wedding)/pn2 (1).webp";
import pn3 from "../assets/coupleFolders/pavithra+nischal(wedding)/pn3 (1).webp";

import pn4 from "../assets/coupleFolders/pavithra+nischal(wedding)/pn4 (1).webp";
import pn5 from "../assets/coupleFolders/pavithra+nischal(wedding)/pn5 (1).webp";
import pn6 from "../assets/coupleFolders/pavithra+nischal(wedding)/pn6 (1).webp";
import pn7 from "../assets/coupleFolders/pavithra+nischal(wedding)/pn7 (1).webp";
import pn8 from "../assets/coupleFolders/pavithra+nischal(wedding)/pn8 (1).webp";
import pn9 from "../assets/coupleFolders/pavithra+nischal(wedding)/pn9 (1).webp";

import pn10 from "../assets/coupleFolders/pavithra+nischal(wedding)/pn10 (1).webp";
import pn11 from "../assets/coupleFolders/pavithra+nischal(wedding)/pn11 (1).webp";
import pn12 from "../assets/coupleFolders/pavithra+nischal(wedding)/pn12 (1).webp";

const pavithraNischalData = [
  pn1,
  pn2,
  pn3,
  pn4,
  pn5,
  pn6,
  pn7,
  pn8,
  pn9,
  pn10,
  pn11,
  pn12,
];

window.addEventListener("scroll", reveal);

function reveal() {
  let reveals = document.querySelectorAll(".grid-item");

  for (let i = 0; i < reveals.length; i++) {
    let windowHeight = window.innerHeight;
    let revealTop = reveals[i].getBoundingClientRect().top;
    let revealPoint = 50; // Adjust this value according to when you want the animation to trigger

    if (revealTop < windowHeight - revealPoint) {
      reveals[i].classList.add("animated", "zoom-in");
      reveals[i].classList.remove("zoom-out");
    } else {
      reveals[i].classList.add("animated", "zoom-out");
      reveals[i].classList.remove("zoom-in");
    }
  }
}

export default function PavithraNischal() {
  return (
    <>
      <div className="bg-[#f5f1eb] pb-[70px]">
        <div className="w-full h-[90px] bg-black"></div>
        <p className="xl:text-[40px] mb-[50px] text-[20px] font-[ameralays] tracking-widest font-extrabold capitalize text-center mt-[50px] xl:mt-[70px]">
          Pavithra & Nischal
        </p>
        <p className="text-[#847f81] font-[BaskervilleRegular] text-[14px] text-center mt-[40px] xl:pl-[200px] xl:pr-[200px] xl:mb-[70px] pl-[15px] pr-[15px] mb-[50px]">
          Pavithra and Nischal Christian's wedding was an enchanting fusion of
          tradition and modern elegance. Set against a backdrop of twinkling
          lights and lush greenery, their vows were exchanged under a canopy of
          stars. Every moment, from their laughter-filled preparations to the
          heartfelt speeches, was a testament to their unique love story. The
          day was filled with whimsical details and vibrant celebrations, making
          it a truly magical and unforgettable event.
        </p>

        <div className="flex flex-col xl:items-center gap-[20px] pl-[15px] pr-[15px] xl:pl-0 xl:pr-0">
          {pavithraNischalData.map((item, index) => (
            <div
              key={index}
              className="flex flex-col grid-item animated zoom-in "
            >
              <img
                src={item}
                alt="destination creative and conceptual photography"
                loading="lazy"
                className="xl:w-[1000px] w-full h-full object-cover"
              />
            </div>
          ))}
        </div>

        <p className="xl:text-[30px] text-[20px] font-[services] font-extrabold capitalize text-center mt-[70px] xl:pl-0 xl:pr-0 pl-[15px] pr-[15px]">
          Can't wait to capture the moments with us ?
        </p>
        <div className="flex justify-center mt-[40px] pl-[15px] pr-[15px] xl:pl-0 xl:pr-0">
          <a href="/contact">
            {" "}
            <button className="bg-[#875239] p-[15px] w-[200px] text-[14px] font-semibold text-white hover:bg-[#ba9a8b] font-[BaskervilleRegular]">
              Book Us Now !
            </button>
          </a>
        </div>
      </div>
    </>
  );
}
