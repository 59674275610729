import kj1 from "../assets/coupleFolders/kshira+jawahar(pre-wedding)/kj1 (1).webp";
import kj2 from "../assets/coupleFolders/kshira+jawahar(pre-wedding)/kj2 (1).webp";
import kj3 from "../assets/coupleFolders/kshira+jawahar(pre-wedding)/kj3 (1).webp";
import kj4 from "../assets/coupleFolders/kshira+jawahar(pre-wedding)/kj4 (1).webp";
import kj5 from "../assets/coupleFolders/kshira+jawahar(pre-wedding)/kj5 (1).webp";
import kj6 from "../assets/coupleFolders/kshira+jawahar(pre-wedding)/kj6 (1).webp";
import kj7 from "../assets/coupleFolders/kshira+jawahar(pre-wedding)/kj7 (1).webp";
import kj8 from "../assets/coupleFolders/kshira+jawahar(pre-wedding)/kj8 (1).webp";
import kj9 from "../assets/coupleFolders/kshira+jawahar(pre-wedding)/kj9 (1).webp";
import kj10 from "../assets/coupleFolders/kshira+jawahar(pre-wedding)/kj10 (1).webp";
import kj11 from "../assets/coupleFolders/kshira+jawahar(pre-wedding)/kj11 (1).webp";
import kj12 from "../assets/coupleFolders/kshira+jawahar(pre-wedding)/kj12 (1).webp";
import kj13 from "../assets/coupleFolders/kshira+jawahar(pre-wedding)/kj13 (1).webp";
import kj14 from "../assets/coupleFolders/kshira+jawahar(pre-wedding)/kj15 (1).webp";
import kj15 from "../assets/coupleFolders/kshira+jawahar(pre-wedding)/kj16 (1).webp";

const kshiraJawaharPreweddingData = [
  kj1,
  kj2,
  kj3,
  kj4,
  kj5,
  kj6,
  kj7,
  kj8,
  kj9,
  kj10,
  kj11,
  kj12,
  kj13,
  kj14,
  kj15,
];
window.addEventListener("scroll", reveal);

function reveal() {
  let reveals = document.querySelectorAll(".grid-item");

  for (let i = 0; i < reveals.length; i++) {
    let windowHeight = window.innerHeight;
    let revealTop = reveals[i].getBoundingClientRect().top;
    let revealPoint = 50; // Adjust this value according to when you want the animation to trigger

    if (revealTop < windowHeight - revealPoint) {
      reveals[i].classList.add("animated", "zoom-in");
      reveals[i].classList.remove("zoom-out");
    } else {
      reveals[i].classList.add("animated", "zoom-out");
      reveals[i].classList.remove("zoom-in");
    }
  }
}

export default function KshiraJawahar() {
  return (
    <>
      <div className="bg-[#f5f1eb] pb-[70px]">
        <div className="w-full h-[90px] bg-black"></div>
        <p className="xl:text-[40px]  mb-[50px] text-[20px] font-[ameralays] tracking-widest font-extrabold capitalize text-center mt-[50px] xl:mt-[70px]">
          Kshira & <span className="ml-[15px]">Jawahar</span>
        </p>
        <p className="text-[#847f81] font-[BaskervilleRegular] text-[14px] text-center mt-[40px] xl:pl-[200px] xl:pr-[200px] xl:mb-[70px] pl-[15px] pr-[15px] mb-[50px]">
          Kshira and Jawahar's pre-wedding shoot was a captivating journey of
          love captured against breathtaking backdrops. From sunlit meadows to
          serene lakeshores, each frame told a story of their bond, filled with
          laughter and quiet moments. Their chemistry blossomed amidst playful
          glances and shared smiles, painting a portrait of anticipation and
          joy. It was a magical exploration of their journey together, set
          against nature's tranquil beauty.
        </p>

        <div className="flex flex-col xl:items-center gap-[20px] pl-[15px] pr-[15px] xl:pl-0 xl:pr-0">
          {kshiraJawaharPreweddingData.map((item, index) => (
            <div
              key={index}
              className="flex flex-col grid-item animated zoom-in"
            >
              <img
                src={item}
                alt="creative wedding photography"
                loading="lazy"
                className="xl:w-[1000px] w-full h-full object-cover"
              />
            </div>
          ))}
        </div>

        <p className="xl:text-[30px] text-[20px] font-[services] font-extrabold capitalize text-center mt-[70px] xl:pl-0 xl:pr-0 pl-[15px] pr-[15px]">
          Can't wait to capture the moments with us ?
        </p>
        <div className="flex justify-center mt-[40px] pl-[15px] pr-[15px] xl:pl-0 xl:pr-0">
          <a href="/contact">
            {" "}
            <button className="bg-[#875239] p-[15px] w-[200px] text-[14px] font-semibold text-white hover:bg-[#ba9a8b] font-[BaskervilleRegular]">
              Book Us Now !
            </button>
          </a>
        </div>
      </div>
    </>
  );
}
