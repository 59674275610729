import { cls } from "../components/Utility";

export default function PrivacyPolicy() {
  return (
    <>
      <div className="bg-black w-full h-[90px]"></div>
      <div
        className={cls(
          "bg-[#f5f1eb] pt-[85px] pb-[30px] w-full",
          "xl:pl-[220px] xl:pr-[220px]"
        )}
      >
        <p className=" text-[30px] font-semibold mt-[20px] mb-[30px] xl:pl-[25px] xl:pr-[25px] pl-[15px] pr-[15px] font-[BaskervilleRegular]">
          Privacy Policy
        </p>
        <p
          className={cls(
            "pl-[15px] pr-[15px] font-[BaskervilleRegular] text-[14px] mt-[10px] text-[#847f81] ",
            "xl:pl-[25px] xl:pr-[25px]"
          )}
        >
          Welcome to SOUVENIR HAPPIE STORIES PRIVATE LIMITED. This Privacy
          Policy is designed to inform you of our policies regarding the
          collection, use, and disclosure of personal information we receive
          from users of our website.
        </p>
        <p
          className={cls(
            "pl-[15px] pr-[15px] font-extrabold font-[BaskervilleRegular] text-[18px] mt-[20px] ",
            "xl:pl-[25px] xl:pr-[25px]"
          )}
        >
          1. Information We Collect
        </p>
        <p
          className={cls(
            "pl-[15px] pr-[15px] font-[BaskervilleRegular] text-[14px] mt-[10px] text-[#847f81] ",
            "xl:pl-[25px] xl:pr-[25px]"
          )}
        >
          At SOUVENIR HAPPIE STORIES PRIVATE LIMITED, we collect the following
          types of personal information:
          <br />
          <br />
          Names
          <br />
          Contact information (email address, phone number)
          <br />
          Event details (date, time, location)
          <br />
          Payment information (credit card details, billing address)
          <br />
          Any other information provided voluntarily by clients
        </p>
        <br />
        <p
          className={cls(
            "pl-[15px] pr-[15px] font-extrabold font-[BaskervilleRegular] text-[18px] mt-[20px] ",
            "xl:pl-[25px] xl:pr-[25px]"
          )}
        >
          {" "}
          2. How We Use Your Information
        </p>
        <p
          className={cls(
            "pl-[15px] pr-[15px] font-[BaskervilleRegular] text-[14px] mt-[10px] text-[#847f81] ",
            "xl:pl-[25px] xl:pr-[25px]"
          )}
        >
          We use your personal information for the following purposes:
          <br />
          <br />
          Scheduling appointments and delivering photography services
          <br />
          Communicating with you about your event, updates, or promotional
          offers
          <br />
          Customizing our services to meet your preferences and needs
          <br />
          Marketing purposes, with your consent
          <br />
          Legal compliance, such as tax requirements or responding to legal
          requests
        </p>
        <p
          className={cls(
            "pl-[15px] pr-[15px] font-extrabold font-[BaskervilleRegular] text-[18px] mt-[20px] ",
            "xl:pl-[25px] xl:pr-[25px]"
          )}
        >
          {" "}
          3. Data Security
        </p>
        <p
          className={cls(
            "pl-[15px] pr-[15px] font-[BaskervilleRegular] text-[14px] mt-[10px] text-[#847f81] ",
            "xl:pl-[25px] xl:pr-[25px]"
          )}
        >
          We take the security of your personal information seriously and
          implement appropriate technical and organizational measures to protect
          it from unauthorized access, disclosure, alteration, or destruction.
        </p>
        <p
          className={cls(
            "pl-[15px] pr-[15px] font-extrabold font-[BaskervilleRegular] text-[18px] mt-[20px] ",
            "xl:pl-[25px] xl:pr-[25px]"
          )}
        >
          4. Data Retention
        </p>
        <p
          className={cls(
            "pl-[15px] pr-[15px] font-[BaskervilleRegular] text-[14px] mt-[10px] text-[#847f81] ",
            "xl:pl-[25px] xl:pr-[25px]"
          )}
        >
          We retain your personal information for as long as necessary to
          fulfill the purposes outlined in this Privacy Policy, or as required
          by law. When we no longer need to retain your information, we will
          securely dispose of it.
        </p>
        <p
          className={cls(
            "pl-[15px] pr-[15px] font-extrabold font-[BaskervilleRegular] text-[18px] mt-[20px] ",
            "xl:pl-[25px] xl:pr-[25px]"
          )}
        >
          5. Third-Party Disclosure
        </p>
        <p
          className={cls(
            "pl-[15px] pr-[15px] font-[BaskervilleRegular] text-[14px] mt-[10px] text-[#847f81] ",
            "xl:pl-[25px] xl:pr-[25px]"
          )}
        >
          We do not sell, trade, or otherwise transfer your personal information
          to third parties without your consent, except as required by law or as
          necessary to fulfill our contractual obligations. However, we may
          share your information with trusted third-party service providers who
          assist us in operating our website, conducting our business, or
          servicing you, as long as those parties agree to keep this information
          confidential.
        </p>
        <p
          className={cls(
            "pl-[15px] pr-[15px] font-extrabold font-[BaskervilleRegular] text-[18px] mt-[20px] ",
            "xl:pl-[25px] xl:pr-[25px]"
          )}
        >
          6. Your Rights
        </p>
        <p
          className={cls(
            "pl-[15px] pr-[15px] font-[BaskervilleRegular] text-[14px] mt-[10px] text-[#847f81] ",
            "xl:pl-[25px] xl:pr-[25px]"
          )}
        >
          You have the right to access, correct, or delete your personal
          information. If you would like to exercise these rights, please
          contact us at [contact email/phone]. Additionally, you may opt-out of
          receiving marketing communications from us at any time by following
          the unsubscribe instructions provided in our communications.
        </p>
        <p
          className={cls(
            "pl-[15px] pr-[15px] font-extrabold font-[BaskervilleRegular] text-[18px] mt-[20px] ",
            "xl:pl-[25px] xl:pr-[25px]"
          )}
        >
          7. Cookies and Tracking Technologies
        </p>
        <p
          className={cls(
            "pl-[15px] pr-[15px] font-[BaskervilleRegular] text-[14px] mt-[10px] text-[#847f81] ",
            "xl:pl-[25px] xl:pr-[25px]"
          )}
        >
          Our website may use cookies or tracking technologies to enhance your
          browsing experience and gather information about how you interact with
          our website. You can control cookies through your browser settings and
          other tools. By using our website, you consent to the use of cookies
          and tracking technologies as described in our Cookie Policy.
        </p>
        <p
          className={cls(
            "pl-[15px] pr-[15px] font-extrabold font-[BaskervilleRegular] text-[18px] mt-[20px] ",
            "xl:pl-[25px] xl:pr-[25px]"
          )}
        >
          8. Policy Updates
        </p>
        <p
          className={cls(
            "pl-[15px] pr-[15px] font-[BaskervilleRegular] text-[14px] mt-[10px] text-[#847f81] ",
            "xl:pl-[25px] xl:pr-[25px]"
          )}
        >
          We reserve the right to update or modify this Privacy Policy at any
          time. Any changes will be effective immediately upon posting the
          updated Privacy Policy on our website. We encourage you to review this
          Privacy Policy periodically to stay informed about how we are
          protecting your information.
        </p>
        <p
          className={cls(
            "pl-[15px] pr-[15px] font-extrabold font-[BaskervilleRegular] text-[18px] mt-[20px] ",
            "xl:pl-[25px] xl:pr-[25px]"
          )}
        >
          9. Children's Privacy
        </p>
        <p
          className={cls(
            "pl-[15px] pr-[15px] font-[BaskervilleRegular] text-[14px] mt-[10px] text-[#847f81] ",
            "xl:pl-[25px] xl:pr-[25px]"
          )}
        >
          We do not knowingly collect personal information from children under
          the age of 13. If you are under 13 years old, please do not provide
          any personal information on our website. If we become aware that we
          have collected personal information from a child under the age of 13
          without parental consent, we will take steps to remove that
          information from our servers.
        </p>
        <p
          className={cls(
            "pl-[15px] pr-[15px] font-extrabold font-[BaskervilleRegular] text-[18px] mt-[20px] ",
            "xl:pl-[25px] xl:pr-[25px]"
          )}
        >
          10. International Transfers
        </p>
        <p
          className={cls(
            "pl-[15px] pr-[15px] font-[BaskervilleRegular] text-[14px] mt-[10px] text-[#847f81] ",
            "xl:pl-[25px] xl:pr-[25px]"
          )}
        >
          Your personal information may be transferred to and processed in
          countries other than your own, where data protection laws may be
          different. By using our website and providing your personal
          information, you consent to such transfers and processing in
          accordance with this Privacy Policy.
        </p>
        <p
          className={cls(
            "pl-[15px] pr-[15px] font-extrabold font-[BaskervilleRegular] text-[18px] mt-[20px] ",
            "xl:pl-[25px] xl:pr-[25px]"
          )}
        >
          11. Contact Us
        </p>
        <p
          className={cls(
            "pl-[15px] pr-[15px] font-[BaskervilleRegular] text-[14px] mt-[10px] text-[#847f81] ",
            "xl:pl-[25px] xl:pr-[25px]"
          )}
        >
          If you have any questions or concerns about our Privacy Policy or our
          data practices, please contact us at hellostoriesbyrg@gmail.com. We
          are committed to addressing your inquiries and ensuring the security
          and confidentiality of your personal information.
        </p>
      </div>
    </>
  );
}
