import { cls } from "../components/Utility";

export default function CancellationAndRefund() {
  return (
    <>
      <div className="bg-black w-full h-[90px]"></div>
      <div
        className={cls(
          "bg-[#f5f1eb] pt-[85px] pb-[30px] w-full",
          "xl:pl-[220px] xl:pr-[220px]"
        )}
      >
        <p className="text-[30px] font-semibold font-[BaskervilleRegular] mt-[20px] mb-[30px] xl:pl-[25px] xl:pr-[25px] pl-[15px] pr-[15px]">
          Cancellation and Refund Policies
        </p>
        <p
          className={cls(
            "pl-[15px] pr-[15px] font-[BaskervilleRegular] text-[14px] mt-[10px] text-[#847f81] ",
            "xl:pl-[25px] xl:pr-[25px]"
          )}
        >
          At SOUVENIR HAPPIE STORIES PRIVATE LIMITED, we strive to provide
          exceptional service to our clients. However, we understand that
          circumstances may arise that require cancellations or changes to your
          booking. Please review our policies regarding cancellations and
          refunds below:
        </p>

        <p
          className={cls(
            "pl-[15px] pr-[15px] font-[BaskervilleRegular] text-[14px] mt-[10px] text-[#847f81] ",
            "xl:pl-[25px] xl:pr-[25px]"
          )}
        >
          1. Cancellation has to be requested within 24 hours of booking the
          shoot for full refund. There will be no refund on the cancellation
          post 24 hours of paying the booking amount.
          <br />
          2. We provide refunds of fees solely in the event of non-attendance by
          service providers or in cases where they have missed 100% of the
          specific event content.
          <br />
          3. Additionally In case of any cancellation of the event only 50% of
          the refund will be provided. <br />
          4. Furthermore, we reserve the sole discretion to decline refunds to
          any party if an event is cancelled on the day of the event or within
          15 Days before the event, when photographers and other service
          providers have already been booked by us, however in such cases we are
          also willing to offer rescheduling of the dates for the services.{" "}
          <br />
        </p>

        <p
          className={cls(
            "pl-[15px] pr-[15px] font-[BaskervilleRegular] text-[14px] mt-[10px] text-[#847f81] ",
            "xl:pl-[25px] xl:pr-[25px]"
          )}
        >
          If you have any questions or concerns about our cancellation and
          refund policies, please don't hesitate to contact us at
          hellostoriesbyrg@gmail.com .We're here to assist you every step of the
          way.
        </p>
      </div>
    </>
  );
}
