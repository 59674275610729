import ay1 from "../assets/coupleFolders/akash+yashashwini(pre-wedding)/ay1 (1).webp";
import ay2 from "../assets/coupleFolders/akash+yashashwini(pre-wedding)/ay2 (1).webp";
import ay3 from "../assets/coupleFolders/akash+yashashwini(pre-wedding)/ay3 (1).webp";
import ay4 from "../assets/coupleFolders/akash+yashashwini(pre-wedding)/ay4 (1).webp";
import ay5 from "../assets/coupleFolders/akash+yashashwini(pre-wedding)/ay5 (1).webp";
import ay6 from "../assets/coupleFolders/akash+yashashwini(pre-wedding)/ay6 (1).webp";
import ay7 from "../assets/coupleFolders/akash+yashashwini(pre-wedding)/ay7 (1).webp";
import ay8 from "../assets/coupleFolders/akash+yashashwini(pre-wedding)/ay8 (1).webp";
import ay9 from "../assets/coupleFolders/akash+yashashwini(pre-wedding)/ay9 (1).webp";
import ay10 from "../assets/coupleFolders/akash+yashashwini(pre-wedding)/ay10 (1).webp";
import ay11 from "../assets/coupleFolders/akash+yashashwini(pre-wedding)/ay11 (1).webp";
import ay12 from "../assets/coupleFolders/akash+yashashwini(pre-wedding)/ay12 (1).webp";
import ay13 from "../assets/coupleFolders/akash+yashashwini(pre-wedding)/ay13 (1).webp";
import ay14 from "../assets/coupleFolders/akash+yashashwini(pre-wedding)/ay14 (1).webp";
import ay15 from "../assets/coupleFolders/akash+yashashwini(pre-wedding)/ay16 (1).webp";
import ay16 from "../assets/coupleFolders/akash+yashashwini(pre-wedding)/ay17 (1).webp";
import ay17 from "../assets/coupleFolders/akash+yashashwini(pre-wedding)/ay18 (1).webp";

const akashyashashwiniPreweddingData = [
  ay1,
  ay2,
  ay3,
  ay4,
  ay5,
  ay6,
  ay7,
  ay8,
  ay9,
  ay10,
  ay11,
  ay12,
  ay13,
  ay14,
  ay15,
  ay16,
  ay17,
];
window.addEventListener("scroll", reveal);

function reveal() {
  let reveals = document.querySelectorAll(".grid-item");

  for (let i = 0; i < reveals.length; i++) {
    let windowHeight = window.innerHeight;
    let revealTop = reveals[i].getBoundingClientRect().top;
    let revealPoint = 50; // Adjust this value according to when you want the animation to trigger

    if (revealTop < windowHeight - revealPoint) {
      reveals[i].classList.add("animated", "zoom-in");
      reveals[i].classList.remove("zoom-out");
    } else {
      reveals[i].classList.add("animated", "zoom-out");
      reveals[i].classList.remove("zoom-in");
    }
  }
}

export default function Akash() {
  return (
    <>
      <div className="bg-[#f5f1eb] pb-[70px]">
        <div className="w-full h-[90px] bg-black"></div>
        <p className="xl:text-[40px] text-[20px] font-[ameralays] tracking-widest font-extrabold capitalize text-center mt-[50px] xl:mt-[70px] mb-[50px]">
          Akash & <span className="ml-[15px]">Yashashwini</span>
        </p>
        <p className="text-[#847f81] font-[BaskervilleRegular] text-[14px] text-center mt-[40px] xl:pl-[200px] xl:pr-[200px] xl:mb-[70px] pl-[15px] pr-[15px] mb-[50px]">
          Akash and Yashashwini's pre-wedding shoot was a delightful blend of
          romance and adventure. Against picturesque landscapes and urban
          cityscapes, their love shone through every frame. From playful moments
          in vibrant streets to serene poses in natural settings, each
          photograph captured their unique chemistry and deep connection. It was
          a beautiful portrayal of their journey towards marriage, filled with
          laughter, tenderness, and the promise of a lifetime together.
        </p>

        <div className="flex flex-col xl:items-center gap-[20px] pl-[15px] pr-[15px] xl:pl-0 xl:pr-0">
          {akashyashashwiniPreweddingData.map((item, index) => (
            <div
              key={index}
              className="flex flex-col grid-item animated zoom-in "
            >
              <img
                src={item}
                alt="hyderabad best wedding photography"
                loading="lazy"
                className="xl:w-[1000px] w-full h-full object-cover"
              />
            </div>
          ))}
        </div>

        <p className="xl:text-[30px] text-[20px] font-[services] font-extrabold capitalize text-center mt-[70px] xl:pl-0 xl:pr-0 pl-[15px] pr-[15px]">
          Can't wait to capture the moments with us ?
        </p>
        <div className="flex justify-center mt-[40px] pl-[15px] pr-[15px] xl:pl-0 xl:pr-0">
          <a href="/contact">
            {" "}
            <button className="bg-[#875239] p-[15px] w-[200px] text-[14px] font-semibold text-white hover:bg-[#ba9a8b] font-[BaskervilleRegular]">
              Book Us Now !
            </button>
          </a>
        </div>
      </div>
    </>
  );
}
