import { IoLogoWhatsapp } from "react-icons/io5";

const WhatsAppChat = () => {
  const handleWhatsAppClick = () => {
    // Add your WhatsApp number here
    const phoneNumber = "9353624245";
    // Default message
    const defaultText = encodeURIComponent("Hi, I have gone through your website.Could you provide me more details.");
    // Generate the WhatsApp chat URL with the default message
    const whatsappUrl = `https://wa.me/${phoneNumber}?text=${defaultText}`;
    // Open the WhatsApp chat link in a new tab
    window.open(whatsappUrl, "_blank");
  };

  return (
    <div>
      <a href="#" onClick={handleWhatsAppClick}>
        <button
          className="text-white font-[Caps] font-semibold text-[16px] text-center w-[150px] h-[40px] pl-[15px] pr-[15px] bg-[#875239] rounded-full"
          style={{ borderTopLeftRadius: "60px", overflow: "hidden" }}
        >
          Chat with us
        </button>
      </a>
    </div>
  );
};

export default WhatsAppChat;
