import an1 from "../../assets/coupleFolders/ashwin+nischitha(pre-wedding)/ashwinnischitha1 (1).webp";
import an2 from "../../assets/coupleFolders/ashwin+nischitha(pre-wedding)/ashwinnischitha2 (1).webp";
import an3 from "../../assets/coupleFolders/ashwin+nischitha(pre-wedding)/ashwinnischitha3 (1).webp";
import an4 from "../../assets/coupleFolders/ashwin+nischitha(pre-wedding)/ashwinnischitha4 (1).webp";
import an5 from "../../assets/coupleFolders/ashwin+nischitha(pre-wedding)/ashwinnischitha5 (1).webp";
import an6 from "../../assets/coupleFolders/ashwin+nischitha(pre-wedding)/ashwinnischitha6 (1).webp";
import an7 from "../../assets/coupleFolders/ashwin+nischitha(pre-wedding)/ashwinnischitha7 (1).webp";
import an8 from "../../assets/coupleFolders/ashwin+nischitha(pre-wedding)/ashwinnischitha8 (1).webp";


const ashwiniNischithaPreweddingData=[an1,an2,an3,an4,an5,an6,an7,an8];




window.addEventListener("scroll", reveal);

function reveal() {
  let reveals = document.querySelectorAll(".grid-item");

  for (let i = 0; i < reveals.length; i++) {
    let windowHeight = window.innerHeight;
    let revealTop = reveals[i].getBoundingClientRect().top;
    let revealPoint = 50; // Adjust this value according to when you want the animation to trigger

    if (revealTop < windowHeight - revealPoint) {
      reveals[i].classList.add("animated", "zoom-in");
      reveals[i].classList.remove("zoom-out");
    } else {
      reveals[i].classList.add("animated", "zoom-out");
      reveals[i].classList.remove("zoom-in");
    }
  }
}

export default function AshwinNischitha() {
  return (
    <>
      <div className="bg-[#f5f1eb] pb-[70px]">
        <div className="w-full h-[90px] bg-black"></div>
        <p className="xl:text-[40px] text-[20px] font-[ameralays] tracking-widest font-extrabold capitalize text-center mt-[50px] mb-[30px] xl:mt-[70px]">
          Ashwin & Nischitha
        </p>
        <p className="text-[#847f81] font-[BaskervilleRegular] text-[14px] text-center mt-[20px] xl:pl-[270px] xl:pr-[270px] xl:mb-[20px] pl-[15px] pr-[15px] mb-[20px]">
          Ashwin and Nischitha's pre-wedding shoot captures their love in a
          fresh, modern style. Each photo is a vibrant, unique moment of their
          journey together. Celebrate their special bond with these beautiful,
          unforgettable images.
        </p>
        <p className="text-[#847f81] font-[BaskervilleRegular] text-[14px] text-center mt-[20px] xl:pl-[270px] xl:pr-[270px] xl:mb-[70px] pl-[15px] pr-[15px] mb-[50px] italic">
          Kalasa, Chikmagalur, Karnataka
        </p>
        <div className="flex flex-col xl:items-center gap-[20px] pl-[15px] pr-[15px] xl:pl-0 xl:pr-0">
          {ashwiniNischithaPreweddingData.map((item, index) => (
            <div
              key={index}
              className="flex flex-col grid-item animated zoom-in "
            >
              <img
                src={item}
                alt="bangalore best prewedding"
                loading="lazy"
                className="xl:w-[1000px] w-full h-full object-cover"
              />
            </div>
          ))}
        </div>

        <p className="xl:text-[30px] text-[20px] font-[services] font-extrabold capitalize text-center mt-[70px] xl:pl-0 xl:pr-0 pl-[15px] pr-[15px]">
          Can't wait to capture the moments with us ?
        </p>
        <div className="flex justify-center mt-[40px] pl-[15px] pr-[15px] xl:pl-0 xl:pr-0">
          <a href="/contact">
            {" "}
            <button className="bg-[#875239] p-[15px] w-[200px] text-[14px] font-semibold text-white hover:bg-[#ba9a8b] font-[BaskervilleRegular]">
              Book Us Now !
            </button>
          </a>
        </div>
      </div>
    </>
  );
}
