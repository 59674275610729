import { cls } from "../components/Utility";
import founder1 from "../assets/pictures/Team/founder1.webp";
import founder2 from "../assets/pictures/Team/Shravanth.webp";
import Call from "../components/Call";
import DesktopWa from "../components/DesktopWa";
import { useState } from "react";

const founderData = [
  {
    profilePhoto: founder1,
    name: "Praveen",
    designation: "Founder & COO",
    description:
      "At Stories by RG, our vision is to capture the essence of every wedding with creativity and care, creating timeless memories while treating each client as family. We are dedicated to setting new standards in the wedding photography industry through innovation and heartfelt service.",
  },
  {
    profilePhoto: founder2,
    name: "Shravanth",
    designation: "Co-Founder & CEO",
    description:
      "Our vision at Stories by RG is to capture not just moments but the emotions and unique stories of each couple, ensuring their special day is immortalized beautifully. We strive for excellence and innovation, making every client feel like family and creating timeless memories",
  },
];

export default function Team() {
  const [result, setResult] = useState("");
  const [showMessage, setShowMessage] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    city: "",
    portfolio: "",
    category: "",
    resume: null, // Include resume field in form data
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFormData((prevData) => ({
      ...prevData,
      resume: file,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setResult("Sending....");
    setShowMessage(true);

    const formDataToSend = new FormData();
    for (const key in formData) {
      formDataToSend.append(key, formData[key]);
    }
    formDataToSend.append("access_key", "f0230624-a744-4ffa-9e32-08cdf679c55d");

    try {
      const response = await fetch("https://api.web3forms.com/submit", {
        method: "POST",
        body: formDataToSend,
      });
      const data = await response.json();

      if (data.success) {
        setResult("Form Submitted Successfully");
        setFormData({
          name: "",
          email: "",
          phoneNumber: "",
          city: "",
          portfolio: "",
          category: "",
          resume: null, // Reset resume field after submission
        });
      } else {
        console.log("Error", data);
        setResult(data.message);
      }
    } catch (error) {
      console.error("Error:", error);
      setResult("An error occurred. Please try again later.");
    }

    setTimeout(() => {
      setShowMessage(false);
    }, 2000);
  };

  return (
    <>
      <div className="bg-[#f5f1eb] relative pb-[20px]">
        <div className="w-full h-[90px] bg-black "></div>

        <h1
          className={cls(
            "text-center text-[20px] font-[ameralays] tracking-widest font-extrabold pl-[15px] pr-[15px] mt-[50px]",
            "xl:text-[30px] "
          )}
        >
          Dream Team Dynamics
        </h1>
        <p className="mt-[30px] text-[14px] pl-[15px] pr-[15px] text-[#847f81] text-center xl:pl-[300px] xl:pr-[300px] font-[BaskervilleRegular]">
          Dream Team Dynamics unites exceptional talent driven by a relentless
          pursuit of excellence. Our collaborative spirit fuels innovation,
          propelling us to exceed expectations and pioneer change. Together, we
          are architects of possibility, shaping the future with every endeavor.
        </p>

        <p
          className={cls(
            "mt-[50px] text-center font-[ameralays] pl-[15px] pr-[15px] tracking-widest font-extrabold text-[20px] text-black",
            "xl:text-[30px]"
          )}
        >
          Our Founders
        </p>

        <p className="mt-[30px] text-[14px] text-[#847f81] text-center xl:pl-[300px] xl:pr-[300px] font-[BaskervilleRegular] pl-[15px] pr-[15px]">
          Meet the minds behind our mission, whose collective vision drives our
          journey forward. Together, they bring diverse expertise and unwavering
          dedication, shaping our path with innovation and purpose.
        </p>

        <div
          className={cls(
            "flex flex-col mt-[40px] gap-[20px] pl-[15px] pr-[15px] ",
            "xl:flex-row xl:gap-[50px] xl:pl-[60px] xl:pr-[60px] xl:justify-center xl:mt-[70px]",
            "md:flex-row md:gap-[50px] md:pl-[60px] md:pr-[60px] md:justify-center md:mt-[40px]"
          )}
        >
          {founderData.map((item, index) => (
            <div
              key={index}
              className="flex flex-col p-[20px] items-center rounded-2xl border-gray-400 border-[1px] w-full xl:w-[400px]"
            >
              <img
                src={item.profilePhoto}
                alt="founders"
                className="rounded-full w-[150px] h-[150px] object-cover "
              />

              <p className="mt-[20px] font-semibold text-black font-[Caps] text-[20px] ">
                {item.name}
              </p>

              <p className="mt-[20px] font-medium text-[18px] text-[#847f81] font-[BaskervilleRegular]">
                {item.designation}
              </p>

              <p className="mt-[20px] text-black text-[14px] text-start font-[BaskervilleRegular]">
                {item.description}
              </p>
            </div>
          ))}
        </div>

        <div className="mt-[50px]">
          <p className="text-center xl:text-[30px] text-[20px] font-extrabold font-[ameralays] tracking-widest mb-[25px] capitalize">
            Join our team{" "}
          </p>
          <p className="text-[14px] text-[#847f81] text-center xl:pl-[300px] xl:pr-[300px] pl-[15px] pr-[15px] font-[BaskervilleRegular]">
            Join our team at Stories by RG and be part of crafting unforgettable
            moments. We're seeking passionate individuals eager to contribute
            their skills in creating exceptional wedding experiences. Apply now
            and help us turn dreams into reality!.
          </p>

          <div
            className={cls(
              "mt-[30px] flex flex-col pl-[15px] pr-[15px]",
              "xl:flex-row xl:justify-center xl:pl-[25px] xl:pr-[25px]"
            )}
          >
            <div className="w-full border-[#A0A0A0] border-[1px] rounded-[45px] flex flex-col p-[25px] gap-[15px] xl:p-[55px] xl:max-w-[600px] md:max-w-[600px]">
              <form onSubmit={handleSubmit}>
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                  autoComplete="name"
                  className="border-[1px] border-[#875239] w-full p-[10px] outline-none placeholder:text-sky-950 text-[14px] font-[BaskervilleRegular] mb-[15px] rounded-2xl"
                  placeholder="Name*"
                />
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                  autoComplete="email"
                  className="border-[1px] border-[#875239] w-full p-[10px] outline-none placeholder:text-sky-950 text-[14px] font-[BaskervilleRegular] rounded-2xl mb-[15px]"
                  placeholder="Email*"
                />
                <input
                  type="tel"
                  name="phoneNumber"
                  autoComplete="phone number"
                  value={formData.phoneNumber}
                  onChange={handleChange}
                  required
                  pattern="[0-9]{3}[0-9]{3}[0-9]{4}"
                  className="border-[1px] border-[#875239] w-full p-[10px] outline-none placeholder:text-sky-950  text-[14px] font-[BaskervilleRegular] rounded-2xl mb-[15px]"
                  placeholder="Phone Number*"
                />

                <input
                  type="text"
                  name="city"
                  autoComplete="city"
                  value={formData.city}
                  onChange={handleChange}
                  required
                  className="border-[1px] border-[#875239] w-full p-[10px] outline-none placeholder:text-sky-950  text-[14px] font-[BaskervilleRegular] mb-[15px] rounded-2xl "
                  placeholder="City*"
                />
                <input
                  type="url"
                  name="portfolio"
                  autoComplete="portfolio"
                  value={formData.portfolio}
                  onChange={handleChange}
                  className="border-[1px] border-[#875239] w-full p-[10px] outline-none placeholder:text-sky-950  text-[14px] font-[BaskervilleRegular] mb-[15px] rounded-2xl "
                  placeholder="Portfolio Link"
                />

                <label htmlFor="category" className="sr-only">
                  Job Category:
                </label>
                <select
                  id="category"
                  name="category"
                  value={formData.category}
                  onChange={handleChange}
                  autoComplete="Job category"
                  className="border-[1px] border-[#875239] w-full p-[10px] outline-none placeholder:text-sky-950  text-[14px] font-[BaskervilleRegular] mb-[15px] rounded-2xl"
                >
                  <option value="" disabled hidden>
                    Job Category
                  </option>
                  <option value="Wedding Photographer">
                    Wedding Photographer
                  </option>
                  <option value="photo editor">Photo Editor</option>
                  <option value=" Video Editor">Video Editor</option>
                  <option value="other">other</option>
                </select>

                <div className="flex justify-center items-center mt-[30px]">
                  <button
                    type="submit"
                    className="bg-[#875339] text-white font-[BaskervilleRegular] pl-[15px] pr-[15px] text-center font-semibold mt-[20px] w-[250px] pt-[10px] pb-[10px] text-[14px] "
                  >
                    Submit
                  </button>
                </div>
              </form>
              {showMessage && (
                <div className="w-full flex justify-center items-center text-[#A4a4a4] font-semibold">
                  Form submitted successfully...
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="mt-[50px] text-center mb-[20px]">
          <p className="font-semibold xl:text-[40px] text-[25px] text-[#875239] mb-[10px] ">
            Call for free consultation at
          </p>
          <div className="font-semibold xl:text-[40px] text-[25px] text-[#875239] mb-[10px] ">
            <Call phoneNumber="+91 9353624245" />
          </div>
        </div>
        <div className="chat-icon">
          <DesktopWa />
        </div>
      </div>
    </>
  );
}
