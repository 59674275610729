import sn1 from "../assets/coupleFolders/sowmya + nihal (prewedding)/sowmyanihal1 (1).webp";
import sn2 from "../assets/coupleFolders/sowmya + nihal (prewedding)/sowmyanihal2 (1).webp";
import sn4 from "../assets/coupleFolders/sowmya + nihal (prewedding)/sowmyanihal4 (1).webp";
import sn5 from "../assets/coupleFolders/sowmya + nihal (prewedding)/sowmyanihal5 (1).webp";
import sn6 from "../assets/coupleFolders/sowmya + nihal (prewedding)/sowmyanihal6 (1).webp";
import sn7 from "../assets/coupleFolders/sowmya + nihal (prewedding)/sowmyanihal7 (1).webp";
import sn8 from "../assets/coupleFolders/sowmya + nihal (prewedding)/sowmyanihal8 (1).webp";
import sn9 from "../assets/coupleFolders/sowmya + nihal (prewedding)/sowmyanihal9 (1).webp";
import sn10 from "../assets/coupleFolders/sowmya + nihal (prewedding)/sowmyanihal10 (1).webp";
import sn11 from "../assets/coupleFolders/sowmya + nihal (prewedding)/sowmyanihal11 (1).webp";
import sn12 from "../assets/coupleFolders/sowmya + nihal (prewedding)/sowmyanihal12 (1).webp";
import sn13 from "../assets/coupleFolders/sowmya + nihal (prewedding)/sowmyanihal13 (1).webp";
import sn14 from "../assets/coupleFolders/sowmya + nihal (prewedding)/sowmyanihal14 (1).webp";
import sn15 from "../assets/coupleFolders/sowmya + nihal (prewedding)/sowmyanihal15 (1).webp";
import sn16 from "../assets/coupleFolders/sowmya + nihal (prewedding)/sowmyanihal16 (1).webp";
import sn17 from "../assets/coupleFolders/sowmya + nihal (prewedding)/sowmyanihal17 (1).webp";
import sn18 from "../assets/coupleFolders/sowmya + nihal (prewedding)/sowmyanihal18 (1).webp";
import sn19 from "../assets/coupleFolders/sowmya + nihal (prewedding)/sowmyanihal19 (1).webp";
import sn20 from "../assets/coupleFolders/sowmya + nihal (prewedding)/sowmyanihal20 (1).webp";
import sn21 from "../assets/coupleFolders/sowmya + nihal (prewedding)/sowmyanihal21 (1).webp";
import sn22 from "../assets/coupleFolders/sowmya + nihal (prewedding)/sowmyanihal22 (1).webp";
import sn23 from "../assets/coupleFolders/sowmya + nihal (prewedding)/sowmyanihal23 (1).webp";
import sn24 from "../assets/coupleFolders/sowmya + nihal (prewedding)/sowmyanihal24 (1).webp";
import sn25 from "../assets/coupleFolders/sowmya + nihal (prewedding)/sowmyanihal25 (1).webp";

const sowmyaNihalPreweddingData = [
  sn1,
  sn2,
  sn4,
  sn5,
  sn6,
  sn7,
  sn8,
  sn9,
  sn10,
  sn11,
  sn12,
  sn13,
  sn14,
  sn15,
  sn16,
  sn17,
  sn18,
  sn19,
  sn20,
  sn21,
  sn22,
  sn23,
  sn24,
  sn25,
];

window.addEventListener("scroll", reveal);

function reveal() {
  let reveals = document.querySelectorAll(".grid-item");

  for (let i = 0; i < reveals.length; i++) {
    let windowHeight = window.innerHeight;
    let revealTop = reveals[i].getBoundingClientRect().top;
    let revealPoint = 50; // Adjust this value according to when you want the animation to trigger

    if (revealTop < windowHeight - revealPoint) {
      reveals[i].classList.add("animated", "zoom-in");
      reveals[i].classList.remove("zoom-out");
    } else {
      reveals[i].classList.add("animated", "zoom-out");
      reveals[i].classList.remove("zoom-in");
    }
  }
}

export default function SowmyaNihalPrewedding() {
  return (
    <>
      <div className="bg-[#f5f1eb] pb-[70px]">
        <div className="w-full h-[90px] bg-black"></div>
        <p className="xl:text-[40px] text-[20px] font-[ameralays] tracking-widest font-extrabold capitalize text-center mt-[50px] mb-[30px] xl:mt-[70px]">
          Sowmya & Nihal
        </p>
        <p className="text-[#847f81] font-[BaskervilleRegular] text-[14px] text-center mt-[20px] xl:pl-[270px] xl:pr-[270px] xl:mb-[70px] pl-[15px] pr-[15px] mb-[50px]">
          Explore the love story of Sowmya and Nihal against the backdrop of
          Dubai's vibrant cityscape. Our photographers captured their journey
          through iconic landmarks and hidden gems, showcasing their connection
          in every frame. From bustling streets to serene waterfronts, each
          photo tells a tale of romance and discovery. Celebrate their unique
          bond with images that blend urban elegance with heartfelt moments.
          Join us in revisiting their unforgettable pre-wedding adventure in the
          dazzling city of Dubai.
        </p>

        <div className="flex flex-col xl:items-center gap-[20px] pl-[15px] pr-[15px] xl:pl-0 xl:pr-0">
          {sowmyaNihalPreweddingData.map((item, index) => (
            <div
              key={index}
              className="flex flex-col grid-item animated zoom-in"
            >
              <img
                src={item}
                alt="international prewedding shoot"
                loading="lazy"
                className="xl:w-[1000px] w-full h-full object-cover"
              />
            </div>
          ))}
        </div>

        <p className="xl:text-[30px] text-[20px] font-[services] font-extrabold capitalize text-center mt-[70px] xl:pl-0 xl:pr-0 pl-[15px] pr-[15px]">
          Can't wait to capture the moments with us ?
        </p>
        <div className="flex justify-center mt-[40px] pl-[15px] pr-[15px] xl:pl-0 xl:pr-0">
          <a href="/contact">
            {" "}
            <button className="bg-[#875239] p-[15px] w-[200px] text-[14px] font-semibold text-white hover:bg-[#ba9a8b] font-[BaskervilleRegular]">
              Book Us Now !
            </button>
          </a>
        </div>
      </div>
    </>
  );
}
