import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { IoMenu, IoClose } from "react-icons/io5";
import company_logo from "../assets/logos/logo.webp";
import WhatsAppChat from "./Whatsapp";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isTop, setIsTop] = useState(true);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const closeMenu = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrolled = window.scrollY;
      if (scrolled === 0) {
        setIsTop(true);
      } else {
        setIsTop(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <nav
        className={`bg-transparent w-full fixed top-0 z-10 transition-all duration-300 ${
          isTop ? "" : "transform -translate-y-full"
        }`}
      >
        <div className="w-full mx-auto px-4 relative">
          <div className="flex justify-between items-center">
            <div className="flex items-center">
              <a href="/">
                <img
                  src={company_logo}
                  alt="Company Logo"
                  onClick={closeMenu}
                  className="w-full max-h-[100px] object-contain sm:w-[100px] sm:max-h-[100px] "
                />
              </a>
            </div>
            <div className="hidden md:items-center xl:items-center space-x-4 font-[Caps] xl:flex xl:flex-row xl:gap-[20px] ">
              <Link
                to="/about"
                className="text-white font-semibold text-[18px] focus:text-[#875239] hover:text-[#875239] focus:underline"
                onClick={closeMenu}
              >
                About Us
              </Link>
              <Link
                to="/portfolio/"
                className="text-white font-semibold text-[18px] focus:text-[#875239] focus:underline hover:text-[#875239]"
                onClick={closeMenu}
              >
                Portfolio
              </Link>
              <Link
                to="/films"
                className="text-white font-semibold text-[18px] focus:text-[#875239] focus:underline hover:text-[#875239]"
                onClick={closeMenu}
              >
                Films
              </Link>
              <Link
                to="/international"
                className="text-white font-semibold text-[18px] focus:text-[#875239] focus:underline hover:text-[#875239]"
                onClick={closeMenu}
              >
                International
              </Link>
              <Link
                to="/services"
                className="text-white font-semibold text-[18px] focus:text-[#875239] focus:underline hover:text-[#875239]"
                onClick={closeMenu}
              >
                Services
              </Link>
              <Link
                to="/team"
                className="text-white font-semibold text-[18px] focus:text-[#875239] focus:underline hover:text-[#875239]"
                onClick={closeMenu}
              >
              Our Team 
              </Link>
              <Link
                to="/contact"
                className="text-white font-semibold text-[18px] focus:text-[#875239] focus:underline hover:text-[#875239]"
                onClick={closeMenu}
              >
                Contact
              </Link>
            </div>
            <div className="mr-[20px]">
              <WhatsAppChat />
            </div>
            <div className="md:flex-row xl:hidden md:justify-between flex items-center sm:flex-row sm:justify-between">
              <button
                onClick={toggleMenu}
                aria-label="button"
                className="text-black focus:outline-none"
              >
                {isOpen ? <IoClose size={26} className="text-[#875239]" /> : <IoMenu size={26} className="text-[#875239]" />}
              </button>
            </div>
          </div>
        </div>
      </nav>
      {/* Mobile Menu */}
      <div
        className={`fixed inset-0 flex flex-col items-center justify-center transition-opacity duration-300 ${
          isOpen ? "bg-white z-20" : "bg-transparent z-0"
        }`}
        style={{
          opacity: isOpen ? 1 : 0,
          pointerEvents: isOpen ? "auto" : "none", // Disable pointer events when menu is closed
          transform: isOpen ? "none" : "translateY(-100%)", // Slide in from top when menu is opened
        }}
      >
        {isOpen && (
          <>
            <div className="flex flex-col gap-6
              font-[Caps] text-[16px] ">
              <Link to="/about" onClick={closeMenu}>
                About Us
              </Link>
              <Link to="/portfolio/" onClick={closeMenu}>
                Portfolio
              </Link>
              <Link to="/films" onClick={closeMenu}>
              Films
              </Link>
              <Link to="/international" onClick={closeMenu}>
              International
              </Link>
              <Link to="/services" onClick={closeMenu}>
                Services
              </Link>
              <Link to="/team" onClick={closeMenu}>
              Our Team 
              </Link>
              <Link to="/contact" onClick={closeMenu}>
                Contact
              </Link>
            </div>
            <div className="mt-4">
              <WhatsAppChat />
            </div>
            <div className="w-full pl-[15px] pr-[15px] absolute top-2 flex flex-col ">
              <div className="flex justify-between">
                <a href="/">
                  <img
                    src={company_logo}
                    alt="Company Logo"
                    onClick={closeMenu}
                    className="w-[80px] h-[80px] object-contain sm:w-[80px] sm:h-[80px] float-left"
                  />
                </a>
                <button
                  onClick={closeMenu}
                  aria-label="button"
                  className="text-black focus:outline-none float-right"
                >
                  <IoClose size={26} className="text-[#875239]" />
                </button>
              </div>
              <hr className="border-t-[0.5px] border-[#A4A4A4] mt-[10px]" />
            </div>
          </>
        )}
      </div>
    </>
  );
};
export default Navbar;