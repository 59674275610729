import sb1 from "../../assets/coupleFolders/shivani+balaji (engagement)/sb1 (1).webp";
import sb2 from "../../assets/coupleFolders/shivani+balaji (engagement)/sb2 (1).webp";
import sb3 from "../../assets/coupleFolders/shivani+balaji (engagement)/sb3 (1).webp";
import sb4 from "../../assets/coupleFolders/shivani+balaji (engagement)/sb4 (1).webp";
import sb5 from "../../assets/coupleFolders/shivani+balaji (engagement)/sb5 (1).webp";
import sb6 from "../../assets/coupleFolders/shivani+balaji (engagement)/sb6 (1).webp";
import sb7 from "../../assets/coupleFolders/shivani+balaji (engagement)/sb6 (1).webp";
import sb8 from "../../assets/coupleFolders/shivani+balaji (engagement)/sb8 (1).webp";
import sb9 from "../../assets/coupleFolders/shivani+balaji (engagement)/sb9 (1).webp";
import sb10 from "../../assets/coupleFolders/shivani+balaji (engagement)/sb10 (1).webp";
import sb11 from "../../assets/coupleFolders/shivani+balaji (engagement)/sb11 (1).webp";
import sb12 from "../../assets/coupleFolders/shivani+balaji (engagement)/sb12 (1).webp";

const shivaniBalajiEngagementdata = [
  sb3,
  sb1,
  sb2,
  sb4,
  sb5,
  sb6,
  sb7,
  sb8,
  sb9,
  sb10,
  sb11,
  sb12,
];

window.addEventListener("scroll", reveal);

function reveal() {
  let reveals = document.querySelectorAll(".grid-item");

  for (let i = 0; i < reveals.length; i++) {
    let windowHeight = window.innerHeight;
    let revealTop = reveals[i].getBoundingClientRect().top;
    let revealPoint = 50; // Adjust this value according to when you want the animation to trigger

    if (revealTop < windowHeight - revealPoint) {
      reveals[i].classList.add("animated", "zoom-in");
      reveals[i].classList.remove("zoom-out");
    } else {
      reveals[i].classList.add("animated", "zoom-out");
      reveals[i].classList.remove("zoom-in");
    }
  }
}

export default function ShivaniBalaji() {
  return (
    <>
      <div className="bg-[#f5f1eb] pb-[70px]">
        <div className="w-full h-[90px] bg-black"></div>
        <p className="xl:text-[40px] text-[20px] font-[ameralays] tracking-widest font-extrabold capitalize text-center mt-[50px] mb-[30px] xl:mt-[70px]">
          Shivani & Balaji
        </p>
        <p className="text-[#847f81] font-[BaskervilleRegular] text-[14px] text-center mt-[20px] xl:pl-[270px] xl:pr-[270px] xl:mb-[20px] pl-[15px] pr-[15px] mb-[20px]">
          Our frames and lens are the witness of grandeur orthodox South -
          Indian engagement ceremony of shivani & balaji. Our hearts felt
          delighted and our lens had memorable tears of the beautiful couple on
          their auspicious day. Each photograph felt pleasure to be part and
          parcel of their big day.
        </p>
        <p className="text-[#847f81] font-[BaskervilleRegular] text-[14px] text-center mt-[20px] xl:pl-[270px] xl:pr-[270px] xl:mb-[70px] pl-[15px] pr-[15px] mb-[50px] italic">
          Bangalore, India
        </p>

        <div className="flex flex-col xl:items-center gap-[20px] pl-[15px] pr-[15px] xl:pl-0 xl:pr-0">
          {shivaniBalajiEngagementdata.map((item, index) => (
            <div
              key={index}
              className="flex flex-col grid-item animated zoom-in "
            >
              <img
                src={item}
                alt="sangeet photography"
                loading="lazy"
                className="xl:w-[1000px] w-full h-full object-cover"
              />
            </div>
          ))}
        </div>

        <p className="xl:text-[30px] text-[20px] font-[services] font-extrabold capitalize text-center mt-[70px] xl:pl-0 xl:pr-0 pl-[15px] pr-[15px]">
          Can't wait to capture the moments with us ?
        </p>
        <div className="flex justify-center mt-[40px] pl-[15px] pr-[15px] xl:pl-0 xl:pr-0">
          <a href="/contact">
            {" "}
            <button className="bg-[#875239] p-[15px] w-[200px] text-[14px] font-semibold text-white hover:bg-[#ba9a8b] font-[BaskervilleRegular]">
              Book Us Now !
            </button>
          </a>
        </div>
      </div>
    </>
  );
}
