import {
  Route,
  BrowserRouter as Router,
  Routes,
  useLocation,
} from "react-router-dom";
import Navbar from "./components/Navbar";
import Home from "./pages/Home";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Team from "./pages/Team";
import Footer from "./components/Footer";
import Portfolio from "./pages/Portfolio";
import { useEffect } from "react";
import TermsAndConditions from "./pages/TermsAndConditions";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import CancellationAndRefund from "./pages/CancellationAndRefund";
import {
  HomeMeta,
  AboutMeta,
  PortfolioMeta,
  ContactMeta,
  TeamMeta,
  ServicesMeta,
  FilmsMeta,
  InternationalMeta,
} from "./components/MetaTags";
import Services from "./pages/Services";
import SowmyaNihalPrewedding from "./pages/SowmyaNihalPrewedding";
import AishwaryaGanapathiWedding from "./pages/AishwaryaGanapathiWedding";
import PrashanthAkshithaWedding from "./pages/PrashanthAkshithaWedding";
import KshiraJawahar from "./pages/KshiraJawahar";
import AkashYashashwini from "./pages/Akash";
import TeenaSai from "./pages/TeenaSai";
import PavithraNischal from "./pages/PavithraNischal";
import SpoorthiIndresh from "./pages/SpoorthiIndresh";
import PragyaRithuraj from "./pages/couplepages/PragyaRithuraj";
import ShivaniBalaji from "./pages/couplepages/ShivaniBalaji";
import KeerthanaVarun from "./pages/couplepages/KeerthanaVarun";
import ChanduAnusha from "./pages/couplepages/ChanduAnusha";
import AshwinNischitha from "./pages/couplepages/AshwinNischitha";
import RahulManasa from "./pages/couplepages/RahulManasa";
import GrishaAnirudh from "./pages/couplepages/GrishaAnirudh";
import AnushaSiddhardth from "./pages/couplepages/AnushaSiddhardth";
import Films from "./pages/Films";
import International from "./pages/International";

function ScrollToTopOnRouteChange() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {
  return (
    <Router>
      <Navbar />
      <ScrollToTopOnRouteChange />

      <Routes>
        <Route
          path="/"
          element={
            <>
              <HomeMeta />
              <Home />
            </>
          }
        />
        <Route
          path="/about"
          element={
            <>
              <About />
              <AboutMeta />
            </>
          }
        />
        <Route
          path="/contact"
          element={
            <>
              <Contact />
              <ContactMeta />
            </>
          }
        />
        <Route path="/get-a-quote" element={<Contact />} />
        <Route
          path="/portfolio/"
          element={
            <>
              <Portfolio />
              <PortfolioMeta />
            </>
          }
        />
        <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route
          path="/cancellation-and-refund"
          element={<CancellationAndRefund />}
        />
        <Route
          path="/services"
          element={
            <>
              <Services />
              <ServicesMeta />
            </>
          }
        />
        <Route
          path="/team"
          element={
            <>
              <Team />
              <TeamMeta />
            </>
          }
        />
        <Route
          path="/films"
          element={
            <>
              <Films />
              <FilmsMeta />
            </>
          }
        />

        <Route
          path="/international"
          element={
            <>
              <International />
              <InternationalMeta />
            </>
          }
        />
        <Route
          path="/pragya-rithuraj-engagement"
          element={
            <>
              <PragyaRithuraj />
            </>
          }
        />
        <Route
          path="/shivani-balaji-engagement"
          element={
            <>
              <ShivaniBalaji />
            </>
          }
        />
        <Route
          path="/keerthana-varun-pre-wedding"
          element={
            <>
              <KeerthanaVarun />
            </>
          }
        />
        <Route
          path="/chandu-anusha-gowda-wedding"
          element={
            <>
              <ChanduAnusha />
            </>
          }
        />
        <Route
          path="/ashwin-nischitha-pre-wedding"
          element={
            <>
              <AshwinNischitha />
            </>
          }
        />

        <Route
          path="/sowmya-nihal-prewedding"
          element={
            <>
              <SowmyaNihalPrewedding />
            </>
          }
        />
        <Route
          path="/aishwarya-ganapathi-wedding"
          element={
            <>
              <AishwaryaGanapathiWedding />
            </>
          }
        />
        <Route
          path="/rahul-manasa-pre-wedding"
          element={
            <>
              <RahulManasa />
            </>
          }
        />

        <Route
          path="/grisha-anirudh-pre-wedding"
          element={
            <>
              <GrishaAnirudh />
            </>
          }
        />
        <Route
          path="/anusha-siddhardth-haldi"
          element={
            <>
              <AnushaSiddhardth />
            </>
          }
        />
       
       
        <Route
          path="/prashanth-akshitha-wedding"
          element={<PrashanthAkshithaWedding />}
        />
      
        <Route path="/kshira-jawahar-pre-wedding" element={<KshiraJawahar />} />
        <Route
          path="/akash-yashashwini-pre-wedding"
          element={<AkashYashashwini />}
        />
       
        <Route path="/teena-sai-pre-wedding" element={<TeenaSai />} />
        <Route path="/pavithra-nischal-wedding" element={<PavithraNischal />} />
        <Route path="/spoorthi-indresh-wedding" element={<SpoorthiIndresh />} />
       

       

        

       

       

        
        
       
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
