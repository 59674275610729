import { Helmet } from "react-helmet";

export const HomeMeta = () => (
  <Helmet>
    <title>Stories By RG - Wedding Photography Services in Bangalore</title>
    <meta
      name="description"
      content="Welcome to Stories By RG, offering professional wedding photography services in Bangalore. Capture your special moments with us."
    />
    <meta
      name="keywords"
      content="wedding, wedding photography, wedding photographer Bangalore, wedding services, Stories By RG"
    />
  </Helmet>
);

export const AboutMeta = () => (
  <Helmet>
    <title>Stories By RG - About Us </title>
    <meta
      name="description"
      content="Learn more about Stories By RG, your trusted wedding photographers in Bangalore. We are dedicated to capturing your special moments."
    />
    <meta
      name="keywords"
      content="about Stories By RG, wedding photographers Bangalore, professional photographers"
    />
  </Helmet>
);

export const PortfolioMeta = () => (
  <Helmet>
    <title>Stories By RG - Our Portfolio </title>
    <meta
      name="description"
      content="Explore the portfolio of Stories By RG, showcasing our wedding photography work in Bangalore. See the moments we’ve captured."
    />
    <meta
      name="keywords"
      content="wedding portfolio, wedding photography Bangalore, Stories By RG portfolio"
    />
  </Helmet>
);
export const FilmsMeta = () => (
  <Helmet>
    <title>Stories By RG - Cinematic Wedding Films</title>
    <meta
      name="description"
      content="Experience the magic of cinematic wedding films by Stories By RG. Discover beautifully crafted, emotionally rich films capturing the essence of your special day."
    />
    <meta
      name="keywords"
      content="cinematic wedding films, wedding cinematography, Stories By RG cinematic films, wedding films Bangalore"
    />
  </Helmet>
);

export const InternationalMeta = () => (
  <Helmet>
    <title>Stories By RG - International Shoots</title>
    <meta
      name="description"
      content="Discover the elegance of international wedding photography with Stories By RG. Explore our stunning portfolio showcasing breathtaking weddings from around the globe."
    />
    <meta
      name="keywords"
      content="international wedding photography, destination wedding photography, Stories By RG international shoots, global wedding photography"
    />
  </Helmet>
);

export const ServicesMeta = () => (
  <Helmet>
    <title>Stories By RG - Our Services</title>
    <meta
      name="description"
      content="Discover the exceptional services offered by Stories By RG, from wedding photography to cinematic films, pre-wedding shoots, and more. We provide personalized and premium services to capture your special moments beautifully."
    />
    <meta
      name="keywords"
      content="wedding photography services, cinematic wedding films, pre-wedding shoots, personalized wedding photography, Stories By RG services"
    />
  </Helmet>
);

export const TeamMeta = () => (
  <Helmet>
    <title>Stories By RG - Meet Our Team </title>
    <meta
      name="description"
      content="Meet the talented team behind Stories By RG, the leading wedding photography company in Bangalore, Hyderabad, and across India. Our team is dedicated to capturing your special moments."
    />
    <meta
      name="keywords"
      content="Stories By RG team, wedding photography team, Bangalore wedding photographers team"
    />
  </Helmet>
);

export const ContactMeta = () => (
  <Helmet>
    <title> Stories By RG - Contact Us </title>
    <meta
      name="description"
      content="Get in touch with Stories By RG for exceptional wedding photography services in Bangalore, Hyderabad, and across India. We're here to capture your special moments."
    />
    <meta
      name="keywords"
      content="contact Stories By RG, wedding photography contact, Bangalore wedding photographers contact"
    />
  </Helmet>
);
