import rm1 from "../../assets/coupleFolders/rahul+manasa(pre-wedding)/rahulmanasa1 (1).webp";
import rm2 from "../../assets/coupleFolders/rahul+manasa(pre-wedding)/rahulmanasa2 (1).webp";
import rm3 from "../../assets/coupleFolders/rahul+manasa(pre-wedding)/rahulmanasa3 (1).webp";
import rm4 from "../../assets/coupleFolders/rahul+manasa(pre-wedding)/rahulmanasa4 (1).webp";
import rm5 from "../../assets/coupleFolders/rahul+manasa(pre-wedding)/rahulmanasa5 (1).webp";
import rm6 from "../../assets/coupleFolders/rahul+manasa(pre-wedding)/rahulmanasa6 (1).webp";
import rm7 from "../../assets/coupleFolders/rahul+manasa(pre-wedding)/rahulmanasa7 (1).webp";
import rm8 from "../../assets/coupleFolders/rahul+manasa(pre-wedding)/rahulmanasa8 (1).webp";
import rm9 from "../../assets/coupleFolders/rahul+manasa(pre-wedding)/rahulmanasa9 (1).webp";

const rahulManasaPreweddingData = [rm1, rm2, rm3, rm4, rm5, rm6, rm7, rm8, rm9];

window.addEventListener("scroll", reveal);

function reveal() {
  let reveals = document.querySelectorAll(".grid-item");

  for (let i = 0; i < reveals.length; i++) {
    let windowHeight = window.innerHeight;
    let revealTop = reveals[i].getBoundingClientRect().top;
    let revealPoint = 50; // Adjust this value according to when you want the animation to trigger

    if (revealTop < windowHeight - revealPoint) {
      reveals[i].classList.add("animated", "zoom-in");
      reveals[i].classList.remove("zoom-out");
    } else {
      reveals[i].classList.add("animated", "zoom-out");
      reveals[i].classList.remove("zoom-in");
    }
  }
}

export default function RahulManasa() {
  return (
    <>
      <div className="bg-[#f5f1eb] pb-[70px]">
        <div className="w-full h-[90px] bg-black"></div>
        <p className="xl:text-[40px] text-[20px] font-[ameralays] tracking-widest font-extrabold capitalize text-center mt-[50px] mb-[30px] xl:mt-[70px]">
          Rahul & Manasa
        </p>
        <p className="text-[#847f81] font-[BaskervilleRegular] text-[14px] text-center mt-[20px] xl:pl-[270px] xl:pr-[270px] xl:mb-[20px] pl-[15px] pr-[15px] mb-[20px]">
          Rahul and Manasa's pre-wedding shoot is a delightful celebration of
          their love. Each photo radiates warmth, joy, and the magic of their
          bond. Discover their enchanting story through these beautifully
          captured, heartfelt moments.
        </p>
        <p className="text-[#847f81] font-[BaskervilleRegular] text-[14px] text-center mt-[20px] xl:pl-[270px] xl:pr-[270px] xl:mb-[70px] pl-[15px] pr-[15px] mb-[50px] italic">
          Kalasa & Udupi, Karnataka
        </p>

        <div className="flex flex-col xl:items-center gap-[20px] pl-[15px] pr-[15px] xl:pl-0 xl:pr-0">
          {rahulManasaPreweddingData.map((item, index) => (
            <div
              key={index}
              className="flex flex-col grid-item animated zoom-in"
            >
              <img
                src={item}
                alt="haldi photography"
                loading="lazy"
                className="xl:w-[1000px] w-full h-full object-cover"
              />
            </div>
          ))}
        </div>

        <p className="xl:text-[30px] text-[20px] font-[services] font-extrabold capitalize text-center mt-[70px] xl:pl-0 xl:pr-0 pl-[15px] pr-[15px]">
          Can't wait to capture the moments with us ?
        </p>
        <div className="flex justify-center mt-[40px] pl-[15px] pr-[15px] xl:pl-0 xl:pr-0">
          <a href="/contact">
            {" "}
            <button className="bg-[#875239] p-[15px] w-[200px] text-[14px] font-semibold text-white hover:bg-[#ba9a8b] font-[BaskervilleRegular]">
              Book Us Now !
            </button>
          </a>
        </div>
      </div>
    </>
  );
}
