import { SetStateAction } from "react";

export function dispatch<T>(value: Partial<T>): SetStateAction<any> {
  return (state: T) => ({ ...state, ...value } as T);
}

function unique(arr: string[]) {
  const map: any = {};
  for (let i = 0; i < arr.length; i++) map[arr[i]] = "";
  return Object.keys(map).join(" ");
}

export function cls(
  ...args: (
    | string
    | number
    | undefined
    | false
    | Record<string, boolean | string | undefined | null | number>
  )[]
) {
  let cls = "";
  for (let i = 0; i < args.length; i++) {
    let arg = args[i];
    if (!arg || arg === "unset") continue;
    if (typeof arg === "object") {
      arg = Object.entries(arg)
        .filter(([c, v]) => !!v)
        .flatMap(([c]) => c)
        .filter(Boolean)
        .join(" ");
    }
    cls = `${cls} ${!!arg ? arg : ""}`;
  }

  return unique(cls.split(" "));
}

export function transform(
  value: number,
  inputPoints: number[],
  outputPoints: number[]
) {
  if (inputPoints.length !== outputPoints.length) {
    throw new Error("Input and output arrays must have the same length.");
  }

  // Sort inputPoints array in ascending order
  const sortedInputPoints = [...inputPoints].sort((a, b) => a - b);

  // Find the index where the value falls between two input points
  let index = 0;
  while (index < sortedInputPoints.length && value > sortedInputPoints[index]) {
    index++;
  }

  // Map the value to the corresponding output range
  if (index === 0) {
    // Before the first input point
    return outputPoints[0];
  } else if (index === sortedInputPoints.length) {
    // After the last input point
    return outputPoints[sortedInputPoints.length - 1];
  } else {
    // Between two input points
    const lowerInput = sortedInputPoints[index - 1];
    const upperInput = sortedInputPoints[index];
    const lowerOutput = outputPoints[index - 1];
    const upperOutput = outputPoints[index];

    const normalizedValue = (value - lowerInput) / (upperInput - lowerInput);
    const mappedValue =
      lowerOutput + normalizedValue * (upperOutput - lowerOutput);

    return mappedValue;
  }
}

export function clamp(value: number, min: number, max: number) {
  return Math.max(min, Math.min(max, value));
}

export function areEqual(objectA: any, objectB: any) {
  for (const key in objectA) {
    if (!Object.prototype.hasOwnProperty.call(objectA, key)) continue;
    if (objectA[key] !== objectB[key]) return false;
  }

  return true;
}

export const parseGennexa = (text: string) => {
  const textArray = text.split(" ");
  return textArray.map((str, i) => {
    const result = str.match(/(GenN)(e)(xa)(.*)/);
    if (result) {
      return (
        <span key={i}>
          {result[1]}
          <span className="text-secondary">{result[2]}</span>
          {result[3]}
          {result[4]}{" "}
        </span>
      );
    }
    return str + " ";
  });
};
