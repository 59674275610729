import { cls } from "../components/Utility";
import { useState } from "react";
import Call from "../components/Call";
import DesktopWa from "../components/DesktopWa";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Helmet } from "react-helmet";

export default function Contact() {
  const [result, setResult] = useState("");
  const [showMessage, setShowMessage] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    event: "",
    happeningDate: "",
    countryCode: "+91",
    phoneNumber: "",
    city: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleDateChange = (date) => {
    setFormData((prevData) => ({
      ...prevData,
      happeningDate: date,
    }));
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    setResult("Sending....");
    setShowMessage(true);

    const formDataToSend = new FormData();
    for (const key in formData) {
      formDataToSend.append(key, formData[key]);
    }
    formDataToSend.append("access_key", "bbfedd98-01de-4af0-8bda-388396a1dff8");

    try {
      const response = await fetch("https://api.web3forms.com/submit", {
        method: "POST",
        body: formDataToSend,
      });
      const data = await response.json();

      if (data.success) {
        setResult("Form Submitted Successfully");
        setFormData({
          name: "",
          event: "",
          countryCode: "+91",
          phoneNumber: "",
          city: "",
          message: "",
        });
      } else {
        console.log("Error", data);
        setResult(data.message);
      }
    } catch (error) {
      console.error("Error:", error);
      setResult("An error occurred. Please try again later.");
    }

    setTimeout(() => {
      setShowMessage(false);
    }, 2000);
  };

  return (
    <>
      <Helmet>
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "Organization",
              "name": "Stories by RG",
              "url": "https://www.storiesbyrg.com/contact",
              "description": "Looking for the best wedding photography why to wait contact us soon"
            }
          `}
        </script>
      </Helmet>
      <div className="xl:mt-[0px] pb-[30px] relative bg-[#f5f1eb]">
        <div className="heroblog"></div>
        <p className="xl:mt-[50px] text-center text-[#847f81] text-[14px] font-[BaskervilleRegular] xl:pl-[200px] xl:pr-[200px] mt-[40px] pl-[15px] pr-[15px]">
          Please fill in the form and provide all the essential details to help
          us to create the best and accurate quote.
        </p>
        <p className="mt-[10px] text-center text-[#847f81] text-[14px] font-[BaskervilleRegular] xl:pl-[200px] xl:pr-[200px] pl-[15px] pr-[15px]">
          we will try to reach out to you with in 24 hours else give us a call
          on the number below to get an instant reply. If it's a peak moment of
          the event.
        </p>
        <p className="text-center font-bold text-black xl:text-[30px] text-[20px] xl:pl-[200px] xl:pr-[200px] xl:mt-[50px]  mt-[40px] font-[ameralays] tracking-widest">
          Meet US{" "}
        </p>

        <p className="mt-[30px] text-center text-[#847f81] text-[14px] font-[BaskervilleRegular] xl:pl-[200px] xl:pr-[200px] pl-[15px] pr-[15px]">
          hellostoriesbyrg@gmail.com
        </p>
        <p className="mt-[10px] text-center text-[#847f81] text-[14px] font-[BaskervilleRegular] xl:pl-[200px] xl:pr-[200px] pl-[15px] pr-[15px]">
          +91 9353624245
        </p>

        <p className="mt-[10px] text-center text-[#847f81] text-[14px] font-[BaskervilleRegular] xl:pl-[200px] xl:pr-[200px] pl-[15px] pr-[15px]">
        First Floor, No: 12/1-1, Palace Cross Road, Bangalore 560020
        </p>

        <p className="mt-[10px] text-center text-[#847f81] text-[14px] font-[BaskervilleRegular] xl:pl-[200px] xl:pr-[200px] pl-[15px] pr-[15px]">
          Hyderabad , Bangalore
        </p>

        <div className={cls("pl-[15px] pr-[15px]")}>
          <div>
            <p className="capitalize xl:text-[30px] text-[20px] font-bold font-[ameralays] tracking-widest text-black text-center xl:mt-[50px] md:mt-[70px] mt-[40px]">
              Services Inquiry Form
            </p>
            <p className="mt-[40px] text-center text-[#847f81] text-[14px] font-[BaskervilleRegular xl:pl-[200px] xl:pr-[200px] pl-[15px] pr-[15px]">
              Thank you for your interest in our photography services! Please
              fill out the form below, and we will get back to you as soon as
              possible.
            </p>
            <div className="flex justify-center">
              <div className="mt-[50px] rounded-2xl xl:px-[80px] xl:py-[30px] px-[30px] py-[25px] bg-[#edecec] border-[1px] border-[#875239] mb-[30px] xl:w-[600px] xl:h-[550px] max-w-full h-[550px] md:w-full md:h-full ">
                <form onSubmit={handleSubmit}>
                  <div className="flex flex-col gap-[20px]">
                    <input
                      type="text"
                      name="name"
                      autoComplete="name"
                      value={formData.name}
                      onChange={handleChange}
                      required
                      className="border-b-[1px] border-[#875239] rounded-xl w-full p-[10px] outline-none placeholder:text-sky-950 text-[14px] font-[BaskervilleRegular]"
                      placeholder="Name*"
                    />

                    <div className="flex gap-[20px] font-[BaskervilleRegular]">
                      <label htmlFor="countryCode" className="sr-only">
                        Country Code:
                      </label>
                      <select
                        id="countryCode"
                        name="countryCode"
                        autoComplete="country code"
                        value={formData.countryCode}
                        onChange={handleChange}
                        className="border-b-[1px] border-[#875239] rounded-xl p-[10px] outline-none placeholder:text-sky-950 text-[14px] w-[100px]"
                      >
                        <option value="+91">+91</option>
                        <option value="+1">+1</option>
                        <option value="+44">+44</option>
                        <option value="+61">+61</option>
                        <option value="+86">+86</option>
                        <option value="+33">+33</option>
                        <option value="+49">+49</option>
                        <option value="+81">+81</option>
                        <option value="+7">+7</option>
                        <option value="+82">+82</option>
                      </select>

                      <input
                        type="tel"
                        autoComplete="phone number"
                        name="phoneNumber"
                        value={formData.phoneNumber}
                        onChange={handleChange}
                        required
                        pattern="[0-9]{3}[0-9]{3}[0-9]{4}"
                        className="border-b-[1px] border-[#875239] rounded-xl p-[10px] outline-none placeholder:text-sky-950 text-[14px] w-full"
                        placeholder="Your Number*"
                      />
                    </div>
                    <input
                      type="text"
                      name="event"
                      autoComplete="event"
                      value={formData.event}
                      onChange={handleChange}
                      className="border-b-[1px] border-[#875239] rounded-xl w-full p-[10px] outline-none placeholder:text-sky-950 text-[14px] font-[BaskervilleRegular]"
                      placeholder="Event"
                    />
                    <DatePicker
                      selected={formData.happeningDate}
                      onChange={handleDateChange}
                      className="border-b-[1px] border-[#875239] rounded-xl p-[10px] outline-none placeholder:text-sky-950 text-[14px] w-full font-[BaskervilleRegular] font-normal"
                      dateFormat="dd/MM/yyyy"
                      id="happeningDate"
                      name="happeningDate"
                      autoComplete="happeningDate"
                      placeholderText="Happening Date"
                    />
                    <input
                      type="text"
                      name="city"
                      autoComplete="city"
                      value={formData.city}
                      onChange={handleChange}
                      required
                      className="border-b-[1px] border-[#875239] rounded-xl  w-full p-[10px] outline-none placeholder:text-sky-950 text-[14px] font-[BaskervilleRegular]"
                      placeholder="City*"
                    />
                    <textarea
                      name="message"
                      autoComplete="message"
                      value={formData.message}
                      onChange={handleChange}
                      required
                      className="border-b-[1px] border-[#875239] rounded-xl w-full p-[10px] outline-none placeholder:text-sky-950 text-[14px] font-[BaskervilleRegular]"
                      placeholder="Message"
                    />
                  </div>

                  <div className="flex justify-center items-center mt-[30px]">
                    <button
                      type="submit"
                      className="bg-[#875339] text-white font-[BaskervilleRegular] pl-[15px] pr-[15px] text-center mt-[20px] w-[250px] pt-[10px] pb-[10px] text-[14px] rounded-xl "
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </div>
              {showMessage && (
                <div className="w-full flex justify-center items-center text-[#A4a4a4] font-semibold text-[14px]">
                  Form submitted successfully...
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="mt-[20px] text-center mb-[20px]">
          <p className="font-semibold xl:text-[40px] text-[25px] text-[#875239] mb-[10px] ">
            Call for free consultation at
          </p>
          <div className="font-semibold xl:text-[40px] text-[25px] text-[#875239] mb-[10px] ">
            <Call phoneNumber="+91 9353624245" />
          </div>
        </div>
      </div>

      <div className="chat-icon">
        <DesktopWa />
      </div>
    </>
  );
}
