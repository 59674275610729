import { cls } from "../components/Utility";

export default function TermsAndConditions() {
  return (
    <>
      <div className="bg-black w-full h-[90px]"></div>
      <div
        className={cls(
          "bg-[#f5f1eb] pt-[85px] pb-[30px] w-full",
          "xl:pl-[220px] xl:pr-[220px]"
        )}
      >
        <p className=" text-[30px] font-semibold font-[BaskervilleRegular] mt-[20px] mb-[30px] xl:pl-[25px] xl:pr-[25px] pl-[15px] pr-[15px]">
          Terms And Conditions
        </p>
        <p
          className={cls(
            "pl-[15px] pr-[15px] font-extrabold font-[BaskervilleRegular] text-[18px] mt-[20px] ",
            "xl:pl-[25px] xl:pr-[25px]"
          )}
        >
          Welcome to SOUVENIR HAPPIE STORIES PRIVATE LIMITED,
        </p>
        <p
          className={cls(
            "pl-[15px] pr-[15px] font-[BaskervilleRegular] text-[14px] mt-[10px] text-[#847f81] ",
            "xl:pl-[25px] xl:pr-[25px]"
          )}
        >
          we understand the significance of your special day. Whether it's your
          wedding day, a family celebration, or a corporate event, we believe in
          the power of capturing those cherished moments that you'll treasure
          for a lifetime. With a blend of creativity and professionalism, our
          team of experienced photographers is dedicated to immortalizing the
          essence and emotion of your event.
        </p>
        <p
          className={cls(
            "pl-[15px] pr-[15px] font-extrabold font-[BaskervilleRegular] text-[18px] mt-[20px] ",
            "xl:pl-[25px] xl:pr-[25px]"
          )}
        >
          Our Commitment to Excellence:
        </p>
        <p
          className={cls(
            "pl-[15px] pr-[15px] font-[BaskervilleRegular] text-[14px] mt-[10px] text-[#847f81] ",
            "xl:pl-[25px] xl:pr-[25px]"
          )}
        >
          We pride ourselves on delivering high-quality photography services
          that exceed expectations. From the initial consultation to the final
          delivery of your images, we are committed to providing an exceptional
          experience. Our goal is not just to capture photographs but to weave a
          visual narrative that reflects the uniqueness of your occasion.
        </p>
        <p
          className={cls(
            "pl-[15px] pr-[15px] font-extrabold font-[BaskervilleRegular] text-[18px] mt-[20px] ",
            "xl:pl-[25px] xl:pr-[25px]"
          )}
        >
          The Agreement:
        </p>
        <p
          className={cls(
            "pl-[15px] pr-[15px] font-[BaskervilleRegular] text-[14px] mt-[10px] text-[#847f81] ",
            "xl:pl-[25px] xl:pr-[25px]"
          )}
        >
          {" "}
          The use of this website and services on this website provided by
          Souveniar happie storie PRIVATE LIMITED (hereinafter referred to as
          "Owner") are subject to the following Terms & Conditions (hereinafter
          the "Terms of Service"), all parts and sub-parts of which are
          specifically incorporated by reference here together with the Privacy
          Policy. Following are the Terms of Service governing your use of
          www.storiesbyrg.com (the "Website"), all pages on the Website and any
          services provided by or on this Website ("Services").
        </p>
        <p
          className={cls(
            "pl-[15px] pr-[15px] font-extrabold font-[BaskervilleRegular] text-[18px] mt-[20px] ",
            "xl:pl-[25px] xl:pr-[25px]"
          )}
        >
          {" "}
          Assent & Acceptance
        </p>
        <p
          className={cls(
            "pl-[15px] pr-[15px] font-[BaskervilleRegular] text-[14px] mt-[10px] text-[#847f81] ",
            "xl:pl-[25px] xl:pr-[25px]"
          )}
        >
          By accessing and using the Website, you affirm that you have carefully
          read and understood these Terms of Service, and you agree to abide by
          them. If you do not agree to these Terms of Service, please exit the
          Website immediately. The Owner grants permission for the use of this
          Website and its Services solely on the condition that you consent to
          these Terms of Service. Additionally, specific Services may be subject
          to additional terms and conditions, forming a separate agreement
          between Users and the Owner.
        </p>
        <p
          className={cls(
            "pl-[15px] pr-[15px] font-extrabold font-[BaskervilleRegular] text-[18px] mt-[20px] ",
            "xl:pl-[25px] xl:pr-[25px]"
          )}
        >
          About The Site:
        </p>
        <p
          className={cls(
            "pl-[15px] pr-[15px] font-[BaskervilleRegular] text-[14px] mt-[10px] text-[#847f81] ",
            "xl:pl-[25px] xl:pr-[25px]"
          )}
        >
          The Website is an online store which carries out sale of the
          following: wedding photography and other related services. We reserve
          the right to refuse service or refuse to sell the products on the
          Website at our sole discretion to anyone for any reason at any time.
          The Website does not screen or censor the users who register on and
          access the Website. You assume all risks associated with dealing with
          other users with whom you come in contact through the Website. You
          agree to use the Website only for lawful purposes without infringing
          the rights or restricting the use of this Website by any third party.
        </p>
        <p
          className={cls(
            "pl-[15px] pr-[15px] font-extrabold font-[BaskervilleRegular] text-[18px] mt-[20px] ",
            "xl:pl-[25px] xl:pr-[25px]"
          )}
        >
          Photography Service Terms:
        </p>
        <p
          className={cls(
            "pl-[15px] pr-[15px] font-[BaskervilleRegular] text-[14px] mt-[10px] text-[#847f81] ",
            "xl:pl-[25px] xl:pr-[25px]"
          )}
        >
          1. Quoted prices are valid for 30 days; subject to change if booking
          is not made within this timeframe. Additionally, any ongoing offers
          will have their own separate timelines for expiry. <br />
          2. Each event is for a duration of 6 hours. Any additional time
          required will be subject to hourly charges
          <br />
          3. Any changes to dates, venue, costumes, or concept must be
          communicated in writing at least 15 days prior to the event. Requests
          made within the final 15 days preceding the event will not be
          accommodated.
          <br />
          4. Scope of Services: The Company will provide photography services to
          the Client as specified in the Client's booking agreement, subject to
          the Photographer's availability. The Photographer will make every
          reasonable effort to capture high-quality images. The Client is
          expected to cooperate with the Photographer for posing and dressing up
          within the duration of the photography session as per the booking
          agreement.
          <br />
          5. Travelling Expenses: Travelling, food, accommodation, Visa fee &
          location charges outside Bangalore & Hyderabad will be paid by client
          or it will be billed as per actuals.
          <br />
          6. Copyright and Usage: The Company retains the copyright to all
          images created during the photography session. The Client is granted a
          non-exclusive, non-transferable license to use the images for personal
          use only. The Client may not sell or otherwise transfer the images to
          any third party.
          <br />
          7. Force Majeure: The Company shall not be liable for any failure or
          delay in performing its obligations under this agreement if such
          failure or delay is caused by an event beyond the Company's reasonable
          control, including but not limited to acts of God, natural disasters,
          war, terrorism, or labour disputes. 8. Notification: The Company will
          send alerts via WhatsApp or SMS to the Client regarding the scheduled
          session, and it is the Client's responsibility to be on a network or
          Wi-Fi to receive the alerts.
          <br />
          9. Model Release: The Client grants the Company permission to use any
          images taken during the photography session for advertising,
          promotional, and display purposes. The Client agrees to obtain written
          permission from any individuals who appear in the images.
          <br />
          10. Client agrees that Stories by RG retains the right to post their
          photos/videos in their social media or display in any kind of
          platform, irrespective of whether it is posted or not posted.
          <br />
          11. The client should provide an HDD, preferably with a capacity of 1
          TB, in order to receive a copy of all the raw data.
          <br />
          12. Event Raw copies will be delivered within 15 days only after the
          hard disk is provided by the client.
          <br />
          13. Albums will be issued approximately within 45 days after the
          selection of photos done by the client.
          <br />
          14. There will be total two reviews after the final draft are created
          by the Production team.
        </p>
        <p
          className={cls(
            "pl-[15px] pr-[15px] font-extrabold font-[BaskervilleRegular] text-[18px] mt-[20px] ",
            "xl:pl-[25px] xl:pr-[25px]"
          )}
        >
          {" "}
          Intellectual Property:
        </p>
        <p
          className={cls(
            "pl-[15px] pr-[15px] font-[BaskervilleRegular] text-[14px] mt-[10px] text-[#847f81] ",
            "xl:pl-[25px] xl:pr-[25px]"
          )}
        >
          You agree that the Website, social media platforms and all Services
          provided by the Owner are the property of the Owner, including all
          copyrights, trademarks, trade secrets, patents, videos and pictures
          and other intellectual property ("Owner IP"). You agree that the Owner
          owns all right, title and interest in and to the Owner IP and that You
          will not use the Owner IP for any unlawful or infringing purpose. You
          agree not to reproduce or distribute the Owner IP in any way,
          including electronically or via registration of any new trademarks,
          trade names, service marks or Uniform Resource Locators (URLs),
          without express written permission from the Owner.
        </p>
        <p
          className={cls(
            "pl-[15px] pr-[15px] font-extrabold font-[BaskervilleRegular] text-[18px] mt-[20px] ",
            "xl:pl-[25px] xl:pr-[25px]"
          )}
        >
          Indemnification:
        </p>
        <p
          className={cls(
            "pl-[15px] pr-[15px] font-[BaskervilleRegular] text-[14px] mt-[10px] text-[#847f81] ",
            "xl:pl-[25px] xl:pr-[25px]"
          )}
        >
          You agree to defend and indemnify the Owner and any of its affiliates
          (if applicable) and hold Us harmless against any and all legal claims
          and demands, including reasonable attorney's fees, which may arise
          from or relate to Your use or misuse of the Website or Services, Your
          breach of these Terms of Service, or Your conduct or actions. You
          agree that the Owner shall be able to select its own legal counsel and
          may participate in its own defence if the Owner wishes.
          <br />
        </p>
        <p
          className={cls(
            "pl-[15px] pr-[15px] font-extrabold font-[BaskervilleRegular] text-[18px] mt-[20px] ",
            "xl:pl-[25px] xl:pr-[25px]"
          )}
        >
          Payment & Fees:
        </p>
        <p
          className={cls(
            "pl-[15px] pr-[15px] font-[BaskervilleRegular] text-[14px] mt-[10px] text-[#847f81] ",
            "xl:pl-[25px] xl:pr-[25px]"
          )}
        >
          Should You register for any of the paid Services on this website or
          purchase any product or service on this website, You agree to pay Us
          the specific monetary amounts required for that product or those
          Services. These monetary amounts ("Fees") will be described to You in
          the Form of a quotation at the time of confirmation process. The final
          amount required for payment, and the bifurcation of payment and due
          dates will be informed via mail to You immediately after the purchase
          confirmation made via booking money. <br />
          <br />
          We reserve the right to refuse service or refuse to sell the products
          on the Website at our sole discretion to anyone for any reason at any
          time.
          <br />
          <br />
          1.Please note that 90% of the payment should be made at least 30 days
          prior to the event date. If in any case where the event is booked with
          Stories by RG with less than 30 days then Payment will be due
          immediately upon order confirmation. <br />
          2.10% of the payment should be made on delivering the raw data. <br />
          3.Quoted prices are exclusive of GST charged at 18%. <br />
          4.You acknowledge that failure to pay the fees by the specified due
          date may result in the Owner placing the event on hold. In such
          circumstances, all progress related to the event will be suspended
          until payment is received. This applies to any delayed payment by the
          Customer(s) of the Owner. <br />
          5.Please be advised that the project will progress to production,
          photo selection and editing only upon receipt of the final 10% payment
          at the time of collecting raw data.
        </p>

        <p
          className={cls(
            "pl-[15px] pr-[15px] font-[BaskervilleRegular] text-[14px] mt-[10px] text-[#847f81] ",
            "xl:pl-[25px] xl:pr-[25px]"
          )}
        >
          Ready to capture your special moments? Contact us today to discuss
          your photography needs!
        </p>
        <p
          className={cls(
            "pl-[15px] pr-[15px] font-[BaskervilleRegular] text-[14px] mt-[10px] ",
            "xl:pl-[25px] xl:pr-[25px]"
          )}
        >
          {" "}
          SOUVENIR HAPPIE STORIES PRIVATE LIMITED | Address: 268, 9th Main Rd,
          Remco Bhel Layout, Ideal Homes Twp, Rajarajeshwari Nagar, Bengaluru,
          Karnataka 560098 | reach out to us at : 8147174727 |
          hellostoriesbyrg@gmail.com
        </p>
      </div>
    </>
  );
}
