
import DesktopWa from "../components/DesktopWa";
import { Helmet } from "react-helmet";
window.addEventListener("scroll", reveal);

function reveal() {
  let reveals = document.querySelectorAll(".grid-item");

  for (let i = 0; i < reveals.length; i++) {
    let windowHeight = window.innerHeight;
    let revealTop = reveals[i].getBoundingClientRect().top;
    let revealPoint = 50; // Adjust this value according to when you want the animation to trigger

    if (revealTop < windowHeight - revealPoint) {
      reveals[i].classList.add("animated", "zoom-in");
      reveals[i].classList.remove("zoom-out");
    } else {
      reveals[i].classList.add("animated", "zoom-out");
      reveals[i].classList.remove("zoom-in");
    }
  }
}

export default function About() {
  return (
    <>
      <Helmet>
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "Organization",
              "name": "Stories by RG",
              "url": "https://www.storiesbyrg.com/about",
              "description": "Learn about our company and team at Stories by RG."
            }
          `}
        </script>
      </Helmet>

      <div className="bg-[#f5f1eb] xl:mt-[0px] xl:pb-[70px] pb-[40px] relative">
        <div className="w-full h-[200px] bg-black flex justify-center xl:p-[150px] pl-[15px] pr-[15px]">
          <p className="font-bold xl:text-[40px] text-[20px] text-white font-[ameralays] tracking-widest xl:mt-0 mt-[120px] ">
            Welcome to Stories by RG...
          </p>
        </div>

        <p className="font-[BaskervilleRegular] text-[14px] text-[#847f81] mt-[50px] xl:text-center xl:pl-[300px] xl:pr-[300px] pl-[15px] pr-[15px] grid-item animated zoom-in ">
          We Stories by RG, we bounded to the principle, Innovation with
          excellence in the wedding industry.We make each and every photograph
          with precious feel to bind the ravishness of the beautiful couple's
          big day memories. We capture every moment of yours with the magical
          lens to store your love. Moreover, we ensure that you enjoy and
          cherish the moments of your magical day, Frame Your Life with us.
        </p>

        <p className="font-[ameralays] tracking-widest text-center font-bold xl:text-[35px] text-[20px] xl:mb-[50px] xl:mt-[50px] mb-[30px] mt-[30px] grid-item animated zoom-in ">
          The Story
        </p>

        <p className="font-[BaskervilleRegular] text-[14px] text-[#847f81] xl:mt-[50px] mt-0 xl:text-center xl:pl-[300px] xl:pr-[300px] pl-[15px] pr-[15px] grid-item animated zoom-in ">
          Stories by RG was founded in 2009 by Praveen, a passionate
          photographer who transformed his love for the craft into a thriving
          profession. The name 'Stories by RG' is a tribute to his teachers,
          <span className="font-bold ml-[5px]">Ramu and Geetha</span> , whose
          guidance and wisdom inspired him to pursue this path. In 2023,
          Shravanth joined as a co-founder, bringing fresh perspectives and
          energy to the team.
          <br />
          <br />
          Over the years, Stories by RG has grown into a 50+ million dollar
          industry, known for delivering exceptional products and services. Our
          ultimate aim is to create employment opportunities while capturing the
          beautiful moments that make every wedding unique.
        </p>

        <p className="font-[ameralays] tracking-widest text-center font-bold xl:text-[35px] text-[20px] xl:mb-[50px] xl:mt-[50px] mb-[30px] mt-[30px] grid-item animated zoom-in ">
          The Vision
        </p>
        <p className="font-[BaskervilleRegular] text-[14px] text-[#847f81] xl:mt-[50px] mt-0 xl:text-center xl:pl-[300px] xl:pr-[300px] pl-[15px] pr-[15px] grid-item animated zoom-in ">
          At Stories by RG, our vision is to create a legacy and make a profound
          impact in the wedding photography industry. Our ultimate goal is to
          establish a seamlessly organized wedding photography company that
          serves clients across India.
          <br />
          <br />
          We stritve to deliver unique, creative, and personalized wedding
          stories. Be an integral part of our clients' special days. Treat our
          clients as family, not just customers. Create a comfortable
          environment for clients to make the best memories. Innovate
          continually, becoming a cherished part of couples' big day
          celebrations.
          <br />
          <br />
          Capture not just images, but the emotions and happiness of each
          moment. Provide a modern approach to an age-old tradition. Stay true
          to our roots and basics while continuously updating ourselves with the
          latest technologies and advancements.
        </p>

        <p className="font-[ameralays] tracking-widest text-center font-bold xl:text-[35px] text-[20px] xl:mb-[50px] xl:mt-[50px] mb-[30px] mt-[30px] grid-item animated zoom-in ">
          The Core Values
        </p>

        <p className="font-[BaskervilleRegular] text-[14px] text-[#847f81] xl:mt-[50px] mt-0 xl:text-center xl:pl-[300px] xl:pr-[300px] pl-[15px] pr-[15px] grid-item animated zoom-in ">
          At Stories by RG, our core values guide everything we do. We are
          committed to:
          <br />
          <br /> Ensuring timely delivery with the best, standalone output.
          <br />
          <br /> Creating benchmarks in the wedding photography industry.
          <br />
          <br />
          Organizing a talented and experienced team. <br />
          <br />
          Tailoring the best memories and experiences for our clients.
        </p>

        <p className="font-[ameralays] tracking-widest text-center font-bold xl:text-[35px] text-[20px] xl:mb-[50px] xl:mt-[50px] mb-[30px] mt-[30px] grid-item animated zoom-in ">
          The Team
        </p>
        <p className="font-[BaskervilleRegular] text-[14px] text-[#847f81] xl:mt-[50px] mt-0 xl:text-center xl:pl-[300px] xl:pr-[300px] pl-[15px] pr-[15px] grid-item animated zoom-in ">
          Our team at Stories by RG consists of 50 highly talented and skillful
          members. We have 10 specialized departments, including SLS, CRM, photo
          editing, and video editing. Our team is dedicated to creating wonders,
          exploring new ideas, and staying updated with the latest trends and
          technologies.
          <br />
          <br />
          Our in-house photographers and album designers are exceptionally
          skillful and talented, ensuring that every project we undertake is
          crafted to perfection.
        </p>

        <p className="font-[ameralays] tracking-widest text-center font-bold xl:text-[35px] text-[20px] xl:mb-[50px] xl:mt-[50px] mb-[30px] mt-[30px] grid-item animated zoom-in ">
          The Services
        </p>
        <p className="font-[BaskervilleRegular] text-[14px] text-[#847f81] xl:mt-[50px] mt-0 xl:text-center xl:pl-[300px] xl:pr-[300px] pl-[15px] pr-[15px] grid-item animated zoom-in ">
          At Stories by RG, we offer a comprehensive range of services to ensure
          your wedding memories are captured beautifully and preserved for a
          lifetime. <br />
          <br />
          Our services include the best 4K ultra videos, cinematic films, and
          teasers, as well as high-quality video albums. We specialize in
          creating extremely innovative photos in candid, portrait, and
          traditional styles, and provide premium albums and photo magazines to
          showcase your memories. Each client also receives AI-enhanced photos
          for a unique touch and complimentary Thaalagiri albums to add an extra
          special touch. We treat every wedding as a personalized experience,
          ensuring everlasting memories.
          <br />
          <br /> Additionally, we offer conceptual pre-wedding and destination
          pre-wedding shoots to capture the excitement leading up to your big
          day.
        </p>
        <p className="font-[ameralays] tracking-widest text-center font-bold xl:text-[35px] text-[20px] xl:mb-[50px] xl:mt-[50px] mb-[30px] mt-[30px] grid-item animated zoom-in ">
          The <span className="ml-[15px]">Acheivements</span>
        </p>

        <p className="font-[BaskervilleRegular] text-[14px] text-[#847f81] xl:mt-[50px] mt-0 xl:text-center xl:pl-[300px] xl:pr-[300px] pl-[15px] pr-[15px] grid-item animated zoom-in ">
          At Stories by RG, we take pride in our notable achievements. We have
          been featured in many prestigious magazines, including the Times of
          India and Wedding Wire, establishing our reputation as premium wedding
          photographers. Our rapid rise to fame is a testament to our dedication
          and exceptional service. We have expanded our presence with two
          branches in Bangalore and Hyderabad, and our portfolio includes
          high-profile celebrity weddings.
        </p>
        <p className="xl:text-[30px] text-[20px] font-[services] font-extrabold capitalize text-center mt-[70px] xl:pl-0 xl:pr-0 pl-[15px] pr-[15px]  ">
          Can't wait to capture the moments with us ?
        </p>
        <div className="flex justify-center mt-[40px] pl-[15px] pr-[15px] xl:pl-0 xl:pr-0">
          <a href="/contact">
            {" "}
            <button className="bg-[#875239] p-[15px] w-[200px] text-[14px] font-semibold text-white hover:bg-[#ba9a8b] font-[BaskervilleRegular]">
              Book Us Now !
            </button>
          </a>
        </div>
        <div className="chat-icon">
          <DesktopWa />
        </div>
      </div>
    </>
  );
}
