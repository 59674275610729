import { CgLink } from "react-icons/cg";
import all1 from "../assets/pictures/portfolio/sowmyanihal (1).webp";
import all17 from "../assets/pictures/portfolio/teenasai (1).webp";

const allData = [
  {
    imgsrc: all1,
    link: "/sowmya-nihal-prewedding",
    coupleName: "Sowmya & Nihal",
  },

  {
    link: "/teena-sai-pre-wedding",
    imgsrc: all17,
    coupleName: "Teena & Sai",
  },
];

export default function International() {
  return (
    <>
     <div className="bg-[#f5f1eb] pb-[70px]">
     <div className="w-full h-[90px] bg-black xl:mb-[70px] mb-[50px]"></div>
      <div className="xl:flex xl:flex-col xl:items-center xl:gap-[70px] hidden">
        {allData.map((item, index) => (
          <div key={index} className="flex flex-col ">
            <a href={item.link}>
              <div className="image-containerall ">
                <img
                  src={item.imgsrc}
                  alt="Recent Imageall"
                  className="recent-imageall "
                />
                <div className="overlayall">
                  <p className="text-[30px] font-bold font-[ameralays] text-center">
                    {item.coupleName}
                  </p>

                  <div className="flex justify-center">
                    <button className="w-[50px] h-[50px] rounded-full bg-[#875239] hover:bg-[#dab4a3] p-[10px] text-white font-semibold text-[14px] font-[BaskervilleRegular] mt-[30px]">
                      <CgLink
                        size={26}
                        className="text-white font-bold ml-[1px]"
                      />
                    </button>
                  </div>
                </div>
              </div>
            </a>
          </div>
        ))}
      </div>

      <div className="flex flex-col gap-[40px]  pl-[15px] pr-[15px] xl:hidden">
        {allData.map((item, index) => (
          <div key={index} className="flex flex-col ">
            <div className="image-containerall1 ">
              <img
                src={item.imgsrc}
                alt="Recent Imageall1"
                className="recent-imageall1"
              />
              <div className="overlayall1">
                <p className="text-[18px] font-bold font-[ameralays] text-center tracking-wide">
                  {item.coupleName}
                </p>

                <a href={item.link}>
                  {" "}
                  <div className="flex justify-center">
                    <button className="w-[50px] h-[50px] rounded-full bg-[#875239] hover:bg-[#dab4a3] p-[10px] text-white font-semibold text-[14px] font-[BaskervilleRegular] mt-[30px]">
                      <CgLink
                        size={26}
                        className="text-white font-bold ml-[1px]"
                      />
                    </button>
                  </div>{" "}
                </a>
              </div>
            </div>
          </div>
        ))}
      </div>

      <p className="xl:text-[30px] text-[20px] font-[services] font-extrabold capitalize text-center mt-[70px] xl:pl-0 xl:pr-0 pl-[15px] pr-[15px]">
          Can't wait to capture the moments with us ?
        </p>
        <div className="flex justify-center mt-[40px] pl-[15px] pr-[15px] xl:pl-0 xl:pr-0">
          <a href="/contact">
            {" "}
            <button className="bg-[#875239] p-[15px] w-[200px] text-[14px] font-semibold text-white hover:bg-[#ba9a8b] font-[BaskervilleRegular]">
              Book Us Now !
            </button>
          </a>
        </div>


      </div>
    </>
  );
}
