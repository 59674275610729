import { cls } from "./Utility";
import logo from "../assets/logos/WHITE.webp";
import { BiLogoInstagramAlt } from "react-icons/bi";
import { TiSocialFacebook } from "react-icons/ti";
import { TiSocialLinkedin } from "react-icons/ti";
import { AiFillYoutube } from "react-icons/ai";
import { RiTwitterXFill } from "react-icons/ri";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <>
      <div className="bg-[black] w-full h-full xl:pl-[50px] xl:pr-[50px] xl:pt-[60px] xl:pb-[60px] pt-[40px] pb-[40px] pl-[25px] pr-[25px] xl:flex xl:flex-col xl:gap-[30px] flex flex-col gap-[30px]">
        <div className="xl:flex xl:flex-row xl:justify-center xl:gap-[60px] flex flex-col gap-[40px]">
          <div className="flex flex-col gap-[20px] xl:w-[250px]">
            <img src={logo} className="w-[100px] h-[100px] object-contain" />
            <div className="xl:flex-row xl:justify-between">
              <div className="flex gap-[10px] xl:gap-[10px]">
                <a
                  href="https://www.instagram.com/storiesby.rg/"
                  aria-label="Read more"
                >
                  <BiLogoInstagramAlt
                    size={20}
                    className="text-white"
                    aria-label="button"
                  />
                </a>
                <a
                  href="https://www.facebook.com/storiesbyrg/"
                  aria-label="Read more"
                >
                  <TiSocialFacebook
                    size={20}
                    className="text-white"
                    aria-label="button"
                  />
                </a>
                <a
                  href="https://www.linkedin.com/company/stories-by-rg/?viewAsMember=true"
                  aria-label="Read more"
                >
                  <TiSocialLinkedin
                    size={20}
                    className="text-white"
                    aria-label="button"
                  />
                </a>
                <a
                  href="https://www.youtube.com/channel/UCn9GaUS7TlcMtuPiZ-PuOLg"
                  aria-label="Read more"
                >
                  <AiFillYoutube
                    size={20}
                    className="text-white "
                    aria-label="button"
                  />
                </a>
                <a
                  href="https://twitter.com/storiesbyrg "
                  aria-label="Read more"
                >
                  <RiTwitterXFill
                    size={14}
                    className="text-white mt-1"
                    aria-label="button"
                  />
                </a>
              </div>
            </div>
          </div>

          <div className="flex flex-col xl:w-[250px]">
            <p className="font-[BalsmiqSansBold] text-[14px] text-white mb-[20px] font-bold">
              CONTACT
            </p>
            <p className="text-[12px] text-white mb-[10px] font-[BalsmiqSansRegular]">
              +91 93536-24245
            </p>
            <p className="text-[12px] text-white mb-[10px] font-[BalsmiqSansRegular]">
              {" "}
              <a href="mailto:hello@storiesbyrg.com">
              hello@storiesbyrg.com
              </a>{" "}
            </p>
            <p className="text-[12px] text-white mb-[10px] font-[BalsmiqSansRegular]">
              {" "}
              First Floor, No: 12/1-1, Palace Cross Road, Bangalore 560020
            </p>
          </div>
          <div className="flex flex-col xl:w-[250px]">
            <p className="font-[BalsmiqSansBold] text-[14px] text-white mb-[20px] font-bold">
              QUICK LINKS
            </p>
            <Link to="/about" aria-label="Read more">
              <p className="text-[12px] text-white mb-[10px] font-[BalsmiqSansRegular]">
                About{" "}
              </p>
            </Link>{" "}
            <Link to="/portfolio/" aria-label="Read more">
              <p className="text-[12px] text-white mb-[10px] font-[BalsmiqSansRegular]">
                Portfolio
              </p>
            </Link>{" "}
            <Link to="/films" aria-label="Read more">
              <p className="text-[12px] text-white mb-[10px] font-[BalsmiqSansRegular]">
                Films
              </p>
            </Link>{" "}
            <Link to="/international" aria-label="Read more">
              <p className="text-[12px] text-white mb-[10px] font-[BalsmiqSansRegular]">
                International
              </p>
            </Link>{" "}
            <Link to="/services" aria-label="Read more">
              {" "}
              <p className="text-[12px] text-white mb-[10px] font-[BalsmiqSansRegular]">
                Services
              </p>
            </Link>{" "}
            <Link to="/team" aria-label="Read more">
              {" "}
              <p className="text-[12px] text-white mb-[10px] font-[BalsmiqSansRegular]">
                Team
              </p>
            </Link>{" "}
            <Link to="/contact" aria-label="Read more">
              {" "}
              <p className="text-[12px] text-white mb-[10px] font-[BalsmiqSansRegular]">
                Contact
              </p>
            </Link>
          </div>

          <div className="flex flex-col xl:w-[250px]">
            <Link to="/terms-and-conditions" aria-label="Read more">
              <p className="text-[12px] text-white mb-[10px] font-[BalsmiqSansRegular]">
                Terms & Conditions
              </p>
            </Link>{" "}
            <Link to="/privacy-policy" aria-label="Read more">
              <p className="text-[12px] text-white mb-[10px] font-[BalsmiqSansRegular]">
                Privacy Policy
              </p>
            </Link>{" "}
            <Link to="/cancellation-and-refund" aria-label="Read more">
              {" "}
              <p className="text-[12px] text-white mb-[10px] font-[BalsmiqSansRegular]">
                Cancellation & Refund
              </p>
            </Link>{" "}
          </div>
        </div>
        <hr className="border-t-[0.5px] border-white  w-full " />
        <p className="text-[12px] text-white font-[BalsmiqSansRegular] xl:self-center">
          copyright ©2024 Stories by RG. All rights reserved
        </p>
      </div>
    </>
  );
}
