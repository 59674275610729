import v1 from "../assets/videos/shivanibalajifilm.webm";
import v2 from "../assets/videos/chandhuanushafilm.webm";
import v3 from "../assets/videos/pavithrafilm.webm";
import v4 from "../assets/videos/srujithavinayfilm.webm";
import v5 from "../assets/videos/reethurajatfilm.webm";
import v6 from "../assets/videos/ssfilm.webm";
import { cls } from "../components/Utility";

export default function Films() {
  return (
    <>
      <div className="bg-[#f5f1eb] pb-[70px]">
        <div className="w-full h-[90px] bg-black"></div>
        <div className={cls("pl-0 pr-0", "xl:pl-0 xl:pr-0")}>
          <div className="w-full xl:h-[500px] h-full object-cover overflow-hidden">
            <video autoPlay loop muted>
              <source
                src={v1}
                type="video/mp4"
                className="w-full xl:h-[200px] h-full object-cover"
              />
            </video>
          </div>

          <div className="w-full xl:h-[500px] h-full object-cover overflow-hidden">
            <video autoPlay loop muted>
              <source
                src={v2}
                type="video/mp4"
                className="w-full xl:h-[200px] h-full object-cover"
              />
            </video>
          </div>
          <div className="w-full xl:h-[500px] h-full object-cover overflow-hidden">
            <video autoPlay loop muted>
              <source
                src={v3}
                type="video/mp4"
                className="w-full xl:h-[200px] h-full object-cover"
              />
            </video>
          </div>
          <div className="w-full xl:h-[500px] h-full object-cover overflow-hidden">
            <video autoPlay loop muted>
              <source
                src={v4}
                type="video/mp4"
                className="w-full xl:h-[200px] h-full object-cover"
              />
            </video>
          </div>
          <div className="w-full xl:h-[500px] h-full object-cover overflow-hidden">
            <video autoPlay loop muted>
              <source
                src={v5}
                type="video/mp4"
                className="w-full xl:h-[200px] h-full object-cover"
              />
            </video>
          </div>
          <div className="w-full xl:h-[500px] h-full object-cover overflow-hidden">
            <video autoPlay loop muted>
              <source
                src={v6}
                type="video/mp4"
                className="w-full xl:h-[200px] h-full object-cover"
              />
            </video>
          </div>
        </div>

        <p className="xl:text-[30px] text-[20px] font-[services] font-extrabold capitalize text-center mt-[70px] xl:pl-0 xl:pr-0 pl-[15px] pr-[15px]">
          Looking to create cinematic films with us ?
        </p>
        <div className="flex justify-center mt-[40px] pl-[15px] pr-[15px] xl:pl-0 xl:pr-0">
          <a href="/contact">
            {" "}
            <button className="bg-[#875239] p-[15px] w-[200px] text-[14px] font-semibold text-white hover:bg-[#ba9a8b] font-[BaskervilleRegular]">
              Book Us Now !
            </button>
          </a>
        </div>
      </div>
    </>
  );
}
