import si1 from "../assets/coupleFolders/spoorthi+indresh(wedding)/si1 (1).webp";
import si2 from "../assets/coupleFolders/spoorthi+indresh(wedding)/si2 (1).webp";
import si3 from "../assets/coupleFolders/spoorthi+indresh(wedding)/si3 (1).webp";
import si4 from "../assets/coupleFolders/spoorthi+indresh(wedding)/si4 (1).webp";
import si5 from "../assets/coupleFolders/spoorthi+indresh(wedding)/si5 (1).webp";
import si6 from "../assets/coupleFolders/spoorthi+indresh(wedding)/si6 (1).webp";
import si7 from "../assets/coupleFolders/spoorthi+indresh(wedding)/si7 (1).webp";
import si8 from "../assets/coupleFolders/spoorthi+indresh(wedding)/si8 (1).webp";


const spoorthiIndreshData = [si1, si2, si3, si4, si5, si6, si7, si8];

window.addEventListener("scroll", reveal);

function reveal() {
  let reveals = document.querySelectorAll(".grid-item");

  for (let i = 0; i < reveals.length; i++) {
    let windowHeight = window.innerHeight;
    let revealTop = reveals[i].getBoundingClientRect().top;
    let revealPoint = 50; // Adjust this value according to when you want the animation to trigger

    if (revealTop < windowHeight - revealPoint) {
      reveals[i].classList.add("animated", "zoom-in");
      reveals[i].classList.remove("zoom-out");
    } else {
      reveals[i].classList.add("animated", "zoom-out");
      reveals[i].classList.remove("zoom-in");
    }
  }
}

export default function SpoorthiIndresh() {
  return (
    <>
      <div className="bg-[#f5f1eb] pb-[70px]">
        <div className="w-full h-[90px] bg-black"></div>
        <p className="xl:text-[40px] text-[20px] font-[ameralays] tracking-widest font-extrabold capitalize text-center mt-[50px] mb-[30px] xl:mt-[70px]">
         Spoorthi & Indresh
        </p>
        <p className="text-[#847f81] font-[BaskervilleRegular] text-[14px] text-center mt-[20px] xl:pl-[270px] xl:pr-[270px] xl:mb-[20px] pl-[15px] pr-[15px] mb-[20px]">
          Grisha and Anirudh's pre-wedding shoot captures their love in every
          frame. The photos radiate joy, excitement, and the deep bond they
          share. Relish their unique story with these enchanting and heartfelt
          images.
        </p>
        <p className="text-[#847f81] font-[BaskervilleRegular] text-[14px] text-center mt-[20px] xl:pl-[270px] xl:pr-[270px] xl:mb-[70px] pl-[15px] pr-[15px] mb-[50px] italic">
          Bangalore, India
        </p>

        <div className="flex flex-col xl:items-center gap-[20px] pl-[15px] pr-[15px] xl:pl-0 xl:pr-0">
          {spoorthiIndreshData.map((item, index) => (
            <div
              key={index}
              className="flex flex-col grid-item animated zoom-in"
            >
              <img
                src={item}
                alt="abroad creative wedding photography"
                loading="lazy"
                className="xl:w-[1000px] w-full h-full object-cover"
              />
            </div>
          ))}
        </div>

        <p className="xl:text-[30px] text-[20px] font-[services] font-extrabold capitalize text-center mt-[70px] xl:pl-0 xl:pr-0 pl-[15px] pr-[15px]">
          Can't wait to capture the moments with us ?
        </p>
        <div className="flex justify-center mt-[40px] pl-[15px] pr-[15px] xl:pl-0 xl:pr-0">
          <a href="/contact">
            {" "}
            <button className="bg-[#875239] p-[15px] w-[200px] text-[14px] font-semibold text-white hover:bg-[#ba9a8b] font-[BaskervilleRegular]">
              Book Us Now !
            </button>
          </a>
        </div>
      </div>
    </>
  );
}
